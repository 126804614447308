import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { IProduct } from '../shared/models/product';
import { IResponse, IOrderData, IDataOrderItems } from '../shared/models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderStatusService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getFull(idhash: string) {
    return this.http.get<IResponse>(
      this.baseUrl +
        'order/ordergetbyidhash/?IDHash=' + idhash
    );
  }

}
