import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IStaticContent } from '../../shared/models/staticContents';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  baseUrl = environment.apiUrl;
  errors: string;

  constructor(private http: HttpClient,
              private router: Router) { }

  loadStaticContents() {
    return this.http.get<IStaticContent[]>(this.baseUrl + 'staticcontent/gethomebanner');
  }

}
