import { Component, OnInit } from '@angular/core';
import { BasketService } from './basket/basket.service';
import { AccountService } from './account/account.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetafrenzyService } from 'ngx-metafrenzy';

declare const GAPPS: any;
declare const bootstrap: any;


declare const COUNTCART: any;

function count_cart_items_quantity(op,count) {
  COUNTCART.QUANTITY(op,count);
}

function googleTags(tittle, url) {
  GAPPS.TAGS(tittle, url);
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'C-Xperto';



  constructor(private basketService: BasketService,
              private accountService: AccountService,
              private router: Router,
              private titleService: Title,
              private readonly metafrenzyService: MetafrenzyService) {

    const url = encodeURIComponent(window.location.href);
    metafrenzyService.setMetaTag('og:title', 'C-xperto');
    metafrenzyService.setMetaTag('og:url', url);
    metafrenzyService.setMetaTag('og:image', '/assets/images/c-experto-open-graph-logo.png');
    metafrenzyService.setMetaTag('description', 'e-learning.');
    metafrenzyService.setMetaTag('og:type', 'Organization');
    metafrenzyService.setMetaTag('keywords', 'E-learning, excel, KPIs, cursos, videocursos');
    metafrenzyService.setMetaTag('fb:app_id', '596404557690650');

    router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        googleTags(document.title, item.url);

        if (!item.url.toString().includes('/categoria/') &&
            !item.url.toString().includes('/articulo/') &&
            !item.url.toString().includes('/carrito') &&
            !item.url.toString().includes('/cuenta/mi-cuenta') &&
            !item.url.toString().includes('/cuenta/sesion') &&
            !item.url.toString().includes('/cuenta/registrarse') &&
            !item.url.toString().includes('/cuenta/ordenes') &&
            !item.url.toString().includes('/estado-orden/') &&
            !item.url.toString().includes('/forma-pago') &&
            !item.url.toString().includes('/cuenta/ser-instructor') &&
            !item.url.toString().includes('/cuenta/instructor') &&
            !item.url.toString().includes('/categorias') &&
            !item.url.toString().includes('/gracias/') &&
            !item.url.toString().includes('/curso/')) {
          titleService.setTitle('c-xperto');

        }

        if (item.url.toString().includes('/carrito')) {
          titleService.setTitle('carrito | c-xperto');

        }

        if (item.url.toString().includes('/cuenta/mi-cuenta')) {
          titleService.setTitle('mi cuenta | c-xperto');

        }

        if (item.url.toString().includes('/cuenta/sesion')) {
          titleService.setTitle('iniciar sesión | c-xperto');

        }

        if (item.url.toString().includes('/cuenta/registrarse')) {
          titleService.setTitle('registarse | c-xperto');

        }

        if (item.url.toString().includes('/cuenta/ordenes')) {
          titleService.setTitle('mis ordenes | c-xperto');

        }

        if (item.url.toString().includes('/estado-orden')) {
          titleService.setTitle('seguimiento de orden | c-xperto');

        }

        if (item.url.toString().includes('/forma-pago')) {
          titleService.setTitle('forma de pago | c-xperto');

        }

        if (item.url.toString().includes('/cuenta/ser-instructor')) {
          titleService.setTitle('ser instructor | c-xperto');

        }

        if (item.url.toString().includes('/cuenta/instructor')) {
          titleService.setTitle('registrarse como instructor | c-xperto');

        }

        if (item.url.toString().includes('/categorias')) {
          titleService.setTitle('categorias | c-xperto');

        }

        if (item.url.toString().includes('/gracias/')) {
          titleService.setTitle('gracias por tu orden | c-xperto');

        }

        if (item.url.toString().includes('/curso/')) {
          titleService.setTitle('tomando curso | c-xperto');

        }

      }
    });

  }

  triggerTooltip()
  {
    console.log('triggerTooltip');
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    console.log(tooltipTriggerList);
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  ngOnInit(): void {
    window.scroll(0,0);
    
    this.loadBasket();
    this.loadCurrentUser();
    this.accountService.getLoadBasketCounter();
    this.triggerTooltip();
  }

  loadCurrentUser() {
    const token = localStorage.getItem('token');
    this.accountService.loadCurrentUser(token).subscribe(() => {
    }, error => {
      console.log(error);
    });
  }

  loadBasket() {
    // if (localStorage.getItem('basket_id')) {
    //   const val = {
    //     'basketID':this.accountService.getSimpleBasketID(),
    //     'userID':this.accountService.getSimpleUserID()
    //   };
    //   this.basketService.getFull(val).subscribe(response => {
    //     if (response.status) {
    //       const totalQuantity = response.data.totalQuantity;
    //       setTimeout(function() { count_cart_items_quantity('init',totalQuantity) },1000);
    //     } else {
    //       setTimeout(function() { count_cart_items_quantity('init',0) },1000);
    //     }
    //   }, error => {
    //     console.log(error);
    //   });
    // }
  }
}
