<!-- Banner -->
<div id="banner-page" class="container-fluid"
     style="background: url(/assets/images/graficos-banner.png) top,
            url(/assets/images/curso-detalle-banner.png);">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3">
                Tu orden ha sido completada
            </h1>
        </div>
    </div>
</div>
<!-- Fin Banner -->

<div id="banner-page" class="container-fluid my-5">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <p class="display-4">
                <b>Nro de Orden: </b>
            </p>
            <p>
                <a href="javascript:void(0)"
                   (click)="goToTrackYourOrder(idhash)">
                    {{ orderNro }}
                </a>
            </p>
            <p>
                <b>Un email fué enviado a: </b>
                <span>
                    {{ emailOrder }}
                </span>
            </p>
            <p class="mt-5">
                <strong class="c-light">Una vez hecha la transferencia, no olvides enviarnos el comprobante de pago al correo de c-xperto: <a class="white-space-nowrap" href="mailto:soporte@c-xperto.com">soporte@c-xperto.com</a></strong>

            </p>
            <br>
            <p>
                <strong class="c-dark">Para pagos en extranjero usar PayPal: <a class="white-space-nowrap" target="_blank" rel="noopener" href="https://www.paypal.me/todoexcel">https://www.paypal.me/todoexcel</a></strong>
                <br>
                <strong class="c-light">Tipo de camio: <a class="white-space-nowrap" href="javascript: void(0)">S/ 3.50 soles x dólar</a></strong>
            </p>
            <button type="button"
                    class="btn btn-primary-gold mt-5"
                    (click)="goToTrackYourOrder(idhash)">
                Ver el Estado de la Orden
            </button>
        </div>
    </div>
</div>
