<header>
    <span *ngIf="totalCount && totalCount > 0">
        Showing 
        <strong>
            {{(pageNumber-1) * pageSize+1}} -
            {{pageNumber*pageSize
                > this.totalCount 
                ? this.totalCount
                : pageNumber*pageSize
            }}
        </strong> of <strong>{{this.totalCount}}</strong> Results</span>
        <span *ngIf="totalCount === 0">
        There are <strong>0</strong> results for this filter
        </span>
</header>