import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';
import { ICategory, ICategoryCardHome } from '../../shared/models/category';
import { IAddToCart, IBasketResponse } from '../../shared/models/basket';

@Injectable({
  providedIn: 'root'
})

export class CoursesService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getCourses(token: string) {
    return this.http.get<ICategory[]>(
      this.apiUrl +
        'category/getForHomeProducts?userID=0');
  }

  getCoursesHome(token: string) {
    return this.http.get<ICategoryCardHome[]>(
      this.apiUrl +
        'category/getForHomeProducts?userID=0');
  }

}
