<!-- \\ Banner -->
<div id="banner-page" class="container-fluid" style="background: url(/assets/images/graficos-banner.png) top, url(/assets/images/banner.jpg);">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3">Pagar</h1>
        </div>
    </div>
</div>
<!-- // Banner -->

<div *ngIf="!empty && (promoCode.length != 0)" class="bloque-texto">
    <div class="container-fluid py-2 pt-5">
        <div class="row">
            <div class="col-12 col-md-6 px-5">
                <div class="row linea-carrito align-items-center text-dark">
                    <div class="col-7 pt-3 text-center text-md-left">
                        <label class="c-light">Cupón de Descuento: </label> <span> {{ promoCode }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Texto -->
<div class="bloque-texto"
     *ngIf="basketItems !== null && basketItems !== []">
    <div class="container-fluid pb-5 {{ promoCode.length == 0 ? 'pt-5' : '' }}">
        <div class="row">
            <div class="col-12 col-md-8 px-5 py-2">
                <div class="row text-center text-white align-items-center bg-purple">
                    <div class="col-6 col-md-8 py-3">
                        Productos
                    </div>
                    <div class="col-3 col-md-1 py-3">
                        Cantidad
                    </div>
                    <div class="col-3 col-md-3 py-3">
                        Precio
                    </div>
                </div>

                <div *ngIf="empty" id="banner-page" class="container-fluid">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-12 col-md-4 text-center">
                            <p class="display-4">No hay productos en su carrito</p>
                        </div>
                    </div>
                </div>
                <div class="row linea-carrito align-items-center text-dark"
                     *ngFor="let item of basketItems">
                    <div class="col-6 col-md-8 py-4 text-center text-md-left">
                        {{item.description}}
                    </div>
                    <div class="col-3 col-md-1 py-4 text-center">
                        {{item.quantity}}
                    </div>
                    <div class="col-3 col-md-3 py-4 text-center">
                        {{item.extendedPrice | currency : 'S/ '}}
                    </div>
                </div>

            </div>
            <div *ngIf="basketTotals" class="co-12 col-md-3 px-5 py-5">
                <h2 class=" mb-5">resumen</h2>
                <div class="row  text-dark pt-3 pb-2">
                    <div class="col-7">Sub-Total</div>
                    <div class="col text-right">{{ basketTotals.subTotal | currency : 'S/ ' }}</div>
                </div>
                <div class="row bg-white  text-dark pt-3 pb-2">
                    <div class="col-7">IGV</div>
                    <div class="col text-right">{{ basketTotals.taxTotal | currency : 'S/ ' }}</div>
                </div>
                <div *ngIf="basketTotals.promoCodeID > 1"
                     class="row bg-white text-dark pt-3 pb-2">
                    <strong class="col-7">Descuento</strong>
                    <div class="col text-right">{{ basketTotals.discountAmount | currency : 'S/ ' }}</div>
                </div>
                <div *ngIf="basketTotals.promoCodeID > 1"
                     class="row  text-white bg-purple mt-5 pt-3 pb-2">
                    <div class="col-7"></div>
                    <div class="col text-right tachado">{{ (basketTotals.total + basketTotals.discountAmount) | currency : 'S/ ' }}</div>
                </div>
                <div class="row  text-white bg-purple pt-3 pb-2">
                    <div class="col-7">Total</div>
                    <div class="col text-right">{{ basketTotals.total | currency : 'S/ ' }}</div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mb-5">
            <div class="col-12 col-md-3">
                <a data-toggle="modal" *ngIf="!isOrderTotalZero" data-target="#pagarTarjeta" class="boton-carrito-tarjeta p-4 d-block">Pagar con Tarjeta</a>
            </div>
            <div class="col-12 col-md-3">
                <a data-toggle="modal" *ngIf="!isOrderTotalZero" data-target="#pagarTransferencia" class="boton-carrito-codigo p-4 d-block">Pagar con Transferencia Bancaria</a>
            </div>
            <div class="col-12 col-md-3">
                <a data-toggle="modal" *ngIf="isOrderTotalZero" data-target="#completarCompra" class="boton-carrito-codigo p-4 d-block">Completar compra</a>
            </div>
        </div>

    </div>
</div>
<!-- Fin Texto -->

<!-- Modal transferencia-->
<div class="modal" id="pagarTransferencia" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title text-purple" id="exampleModalCenteredLabel">Pagar con transferencia bancaria</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body px-4">
                <div class="row text-grey">
                    <div class="col-8">Transferencia bancaria</div>
                    <div class="col text-right">
                        Total: <span class="text-purple font-weight-bold">
                            {{ totals | currency : 'S/ ' }}
                        </span>
                    </div>
                </div>
                <hr>
                <div class="w-100 text-center py-5">
                    <p>
                        <strong class="c-light">Una vez hecha la transferencia, no olvides enviarnos el comprobante de pago al correo de c-xperto: <a class="white-space-nowrap" href="mailto:soporte@c-xperto.com">soporte@c-xperto.com</a></strong>
                    </p>
                    <br>
                    <p>
                        <strong class="c-dark">Para pagos en extranjero usar PayPal: <a class="white-space-nowrap" target="_blank" rel="noopener" href="https://www.paypal.me/todoexcel">https://www.paypal.me/todoexcel</a></strong>
                        <br>
                        <strong class="c-light">Tipo de camio: <a class="white-space-nowrap" href="javascript: void(0)">S/ 3.50 soles x dólar</a></strong>
                    </p>
                    <table class="tableOptzional optional pt-5 mt-5" border="0">
                        <tbody>
                            <tr border="1">
                                <th>
                                    <img src="/assets/images/cuenta-corriente.png"/>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer text-center">
                <div class="row w-100 justify-content-center">
                    <div class="col-12 col-md-8">
                        <a href="javascript: void(0)"
                           (click)="placeorderWT(basketItems,'Transferencia Bancaria')"
                           class="boton-carrito-codigo d-block py-3 px-4 w-100">Pagar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- FIN Modal transferencia-->

<!-- Modal Taejeta-->


	<noscript>
		<iframe style="width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;" [src]="payusecuritySafe"></iframe>
	</noscript>

<div class="modal" id="pagarTarjeta" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="cardForm" (ngSubmit)="onSubmit(basketItems)">
                <div class="modal-header">
                    <h3 class="modal-title text-purple" id="exampleModalCenteredLabel">tarjeta de crédito / débito</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body px-4">
                    <div class="row text-grey">
                        <div class="col text-right">
                            Total: <span class="text-purple font-weight-bold">
                                {{ totals | currency : 'S/ ' }}
                            </span>
                        </div>
                    </div>
                    <hr>
                    <div class="form-row  pt-5 pb-4">
                        <div class="form-group col-12 col-md-6 mb-4">
                            <app-text-input-length
                                formControlName="cardNumber"
                                [label]="'Número de Tarjeta'"
                                [type]="'cardNumber'"
                                [ngModel]="cardNumber">
                            </app-text-input-length>
                        </div>
                        <div class="form-group col-12 col-md-6 mb-4">
                            <app-text-input
                                formControlName="name"
                                [label]="'Nombre'"
                                [type]="'name'"
                                [ngModel]="name">
                            </app-text-input>
                        </div>
                        <div class="form-group col-12 col-md-4 mb-4">
                            <select formControlName="month"
                                    class="form-control">
                                <option [ngValue]="month"
                                        *ngFor="let month of listMonthNumber">
                                    {{ month.monthNumberName }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-12 col-md-4 mb-4">
                            <select formControlName="year"
                                    class="form-control">
                                <option [ngValue]="year"
                                        *ngFor="let year of listYear">
                                    {{ year.year }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-12 col-md-4 mb-4">
                            <app-text-input
                                formControlName="CCV"
                                [label]="'CCV'"
                                [type]="'CCV'"
                                [ngModel]="CCV">
                            </app-text-input>
                        </div>
                        <div class="form-group col-12 col-md-6 mb-4">
                            <app-text-input
                                formControlName="login"
                                [label]="'Correo'"
                                [type]="'login'"
                                [ngModel]="login">
                            </app-text-input>
                        </div>
                        <div class="form-group col-12 col-md-6 mb-4">
                            <app-text-input
                                formControlName="documentNumber"
                                [label]="'Nro de Documento'"
                                [type]="'documentNumber'"
                                [ngModel]="documentNumber">
                            </app-text-input>
                        </div>
                        <div class="form-group col-12 mb-4">
                            <app-text-input
                                formControlName="address1"
                                [label]="'Dirección 1'"
                                [type]="'address1'"
                                [ngModel]="address1">
                            </app-text-input>
                        </div>
                        <div class="form-group col-12 mb-4">
                            <app-text-input
                                formControlName="address2"
                                [label]="'Dirección 2'"
                                [type]="'address2'"
                                [ngModel]="address2">
                            </app-text-input>
                        </div>

                        <div class="form-group col-12 col-md-4 mb-4">
                            <select formControlName="country"
                                    class="form-control">
                                <option [ngValue]="country"
                                        *ngFor="let country of listAllCountries">
                                    {{ country.countryName }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-12 col-md-4 mb-4">
                            <app-text-input
                                formControlName="city"
                                [label]="'Ciudad'"
                                [type]="'city'"
                                [ngModel]="city">
                            </app-text-input>
                        </div>
                        <div class="form-group col-12 col-md-4 mb-4">
                            <app-text-input
                                formControlName="state"
                                [label]="'Departamento'"
                                [type]="'state'"
                                [ngModel]="state">
                            </app-text-input>
                        </div>
                        <div class="form-group col-12 col-md-6 mb-4">
                            <app-text-input
                                formControlName="postalCode"
                                [label]="'Código Postal'"
                                [type]="'postalCode'"
                                [ngModel]="postalCode">
                            </app-text-input>
                        </div>
                        <div class="form-group col-12 col-md-6 mb-4">
                            <app-text-input
                                formControlName="phoneNumber"
                                [label]="'Nro Telefónico'"
                                [type]="'phoneNumber'"
                                [ngModel]="phoneNumber">
                            </app-text-input>
                        </div>
                    </div>
                </div>

                <div class="text-center text-danger list-unstyled" *ngIf="messages.length > 0">
                    <p *ngFor="let message of messages">
                        {{ message }}
                    </p>
                </div>

                <div class="modal-footer text-center">
                    <div class="row w-100 justify-content-center">
                        <div class="col-12 col-md-8">
                            <a (click)="onSubmit(basketItems)"
                                class="boton-carrito-codigo d-block py-3 px-4 w-100">
                                Pagar
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- FIN Modal Tarjeta-->


<!-- Completar Compra Orden Total 0-->
<div class="modal" id="completarCompra" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title text-purple" id="exampleModalCenteredLabel">Completar Compra</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button> 
            </div>
            <div class="modal-body px-4">
                <div class="row text-grey">
                    <div class="col-8">&nbsp;</div>
                    <div class="col text-right">
                        Total: <span class="text-purple font-weight-bold">
                            {{ totals | currency : 'S/ ' }}
                        </span>
                    </div>
                </div>
                <hr>
            </div>
            <div class="modal-footer text-center">
                <div class="row w-100 justify-content-center">
                    <div class="col-12 col-md-8">
                        <a href="javascript: void(0)"
                           (click)="placeorderTotalZero(basketItems,'Total Orden Cero')"
                           class="boton-carrito-codigo d-block py-3 px-4 w-100">Completar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- FIN Completar Compra Orden Total 0-->