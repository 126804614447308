import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { BannerService } from './banner.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IStaticContent } from '../../shared/models/staticContents';

declare const ll: any;

function lazyLoading() {
  ll.update();
}
let ancle = 0;
function rNextC(response) {
  ancle++;
  for(let i = 1;i < response.length;i++) {
    if(ancle === i) {
      return i;
    }
  }
  return ancle = 0;
}

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  staticContent: IStaticContent[];
  cInt: string;
  cPause: boolean;
  couponForm: FormGroup;

  constructor(private fb: FormBuilder,
              private bannerService: BannerService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService) { }

  ngOnInit() {
    window.scroll(0,0);
    setTimeout(lazyLoading,500);
    this.staticContent = [];
    this.getStaticContents();
    this.cInt = 'a0';
    this.cPause = false;
  }

  getStaticContents() {
    this.bannerService.loadStaticContents().subscribe(response => {
      if(response.length > 0) {
        this.staticContent = response;
        const self = this;
        setInterval(function(){if(!self.cPause){self.cInt='a'+rNextC(response);}},4000);
      }
    }, error => {
      console.log(error);
    });
  }

  pauseCarousel() {
    this.cPause = true;
    if(typeof this.cInt === "undefined") {
      this.cInt = 'a0';
    }
    const self = this;
    setTimeout(function(){self.cPause = false;},8000);
  }

}
