<!-- \\ Banner -->
<div id="banner-page" class="container-fluid" style="background: url(/assets/images/graficos-banner.png) top, url(/assets/images/banner.jpg);">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3">Categorías</h1>
        </div>
    </div>
</div>
<!-- // Banner -->

<!-- \\ Categorias -->
<div>
    <div class="row categories">
        <!-- \\ category loop -->
        <div class="col-12 categorias px-0" *ngFor="let category of categories">
            <a routerLink="{{category.url}}">
                <div class="nombre-categoria text-right h1 mb-0 py-4 text-white pr-4 pl-5">
                    {{ category.category }}
                </div>
                <img class="lazy"
                     src="/assets/images/categoria1-empty.png"
                     data-src="{{ category.imageFileName }}"
                     alt="{{ category.category }}">
            </a>
        </div>
        <!-- // category loop -->
    </div>
</div>
<!-- // Categorias -->
