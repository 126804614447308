import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IRequest, IResponse } from '../shared/models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  baseUrl = environment.apiUrl;

  constructor( private http: HttpClient ) { }

  categoryGet(req: IRequest) {
    return this.http.post<IResponse>(this.baseUrl + 'category/get', req).pipe(
      map((response: IResponse) => {
        return response;
      }, error => {
        console.log(error);
      })
    );
  }

}
