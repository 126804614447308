<!-- Banner -->
<div id="banner-page" class="container-fluid"
     style="background: url(/assets/images/graficos-banner.png) top, url(/assets/images/curso-detalle-banner.png);">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3"> {{ courseTitle }} </h1>
        </div>
    </div>
</div>
<!-- Fin Banner -->

<!-- Texto -->
<div *ngIf="status" class="container-fluid">
    <div class="row">
        <div id="contenido" class="col-12 col-md-8 py-5 my-4">

            <figure>
                <video id="videoplaylist" playsinline controls poster='{{ poster }}'>
                    <source src="{{ initLink }}" type="video/mp4"/>
                    <p><em>Su navegador no admite la etiqueta video, descargue el video en su lugar.</em></p>
                </video>
                <figcaption>
                    <h2 id="settitle" class="p-5">{{ title }}</h2>
                </figcaption>
            </figure>

            <!-- Detalle -->
            <div class="bloque-texto mt-1 py-5">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-9">
                            <p id="setdescription">{{ description }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Fin Detalle -->

        </div>
        <div id="resumen" [attr.data-count]="counter" class="col-12 col-md-4 py-5 my-4">
            <div class="sticky-bar">
                <div class="bg-purple d-flex px-4 py-3 align-items-center justify-content-between">
                    <div style="{{['visibility:hidden;',''][isOrderCompleted?1:0]}}"
                         class="d-flex text-white align-items-center">
                        <div class="pie mr-3" [ngStyle]="{ 'background-image' : courseProgressPercentage }"></div>
                        {{ ['Tu ',''][(watched === totals) && (isOrderCompleted) ? 1 : 0] }}
                        Progreso
                        {{['',full]['conic-gradient(#b52a81 100%, #c3c3c3 0%)' === courseProgressPercentage?1:0]}}
                    </div>
                    <a onClick="return FBAPPS.SHARE(400, 300)"
                       href="https://facebook.com/sharer.php?u={{ url }}">
                        <i class="fas fa-share"></i>
                        Compartir
                    </a>
                </div>


                <h2 id="lista-de-videos" ><a class="headerlink" href="#lista-de-videos" title="Permanent link"></a></h2>
                <div *ngFor="let content of chapters; let i = index" class="accordion" id="accordion{{content.productChapterID}}">
                    <!-- collapse1 -->
                    <div class="card mt-4">
                        <div class="card-header" id="heading{{ content.productChapterID }}">
                            <button class="fix-box btn btn-link btn-block d-flex align-items-center justify-content-between text-left collapsed"
                                    type="button" data-toggle="collapse"
                                    [attr.data-target]="'#collapse'+content.productChapterID"
                                    aria-expanded="true"
                                    [attr.aria-controls]="'#collapse'+content.productChapterID">
                                {{ content.productChapterName }} <i class="fas fa-minus"></i><i class="fas fa-plus"></i>
                            </button>
                        </div>

                        <div id="collapse{{ content.productChapterID }}"
                             class="collapse"
                             [attr.aria-labelledby]="'heading'+content.productChapterID"
                             [attr.data-parent]="'#accordion'+content.productChapterID">
                            <aside id="playlist{{ i }}" class="card-body">
                                <ul *ngFor="let detalle of content.detalle; let d = index"
                                    class="capitulo-linea {{ ['d-flex','row'][detalle.resources.length > 0 ? 1 : 0] }} justify-content-between py-3">
                                    <li class="d-flex {{ ['','col-12 col-sm-7'][detalle.resources.length > 0 ? 1 : 0] }} align-items-center">
                                        <input [checked]="detalle.isCompleted"
                                               type="checkbox" class="mr-3"
                                               id="chk{{detalle.courseResourcesProgressID}}"
                                               onClick="return false;">
                                        <a href="{{detalle.productChapterDetailLink}}"
                                           rel="{{ detalle.productChapterDetailID }}"
                                           [attr.data-rel]="detalle.productChapterID"
                                           (click)="shows(detalle.productChapterDetailName,detalle.productChapterDetailDescription)">
                                            {{detalle.productChapterDetailName}}
                                        </a>
                                        <b class="ml-2">
                                            <small class="color-58648c white-space-nowrap">
                                                <i class="fas fa-clock"></i> {{ detalle.productChapterDetailDuration }} min
                                            </small>
                                        </b>
                                    </li>
                                    <div *ngIf="detalle.resources.length > 0"
                                         class="col-12 col-sm-5 pt-3 pt-sm-0 text-right">
                                        <div class="btn-group dropleft">
                                            <button type="button"
                                                    class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Recursos
                                            </button>
                                            <div class="dropdown-menu">
                                                <a *ngFor="let resource of detalle.resources"
                                                   class="dropdown-item"
                                                   download
                                                   target="_BLANK"
                                                   href="{{ resource.productChapterDetailResourceLink }}">
                                                    {{ resource.productChapterDetailResourceName }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </aside>
                        </div>
                    </div>
                    <!-- FIN collapse1 -->
                </div>

                <div class="bg-purple d-flex px-4 py-4 align-items-center justify-content-end text-right mt-4">
                    <a style="{{['visibility:hidden;',''][isOrderCompleted?1:0]}}"
                       href="javascript: void(0)"
                       (click)="goToCalification();">
                        <i class="fas fa-star"></i>
                        Calificar curso
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin Texto -->

<span id="setArrayEndedIDs" style="visibility:hidden;"></span>
