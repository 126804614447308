<!-- Banner -->
<div *ngIf="staticContent.length === 1"
     id="banner-page"
     class="banner-top container-fluid"
     style="background: url(/assets/images/graficos-banner.png) top, url(/assets/images/banner.jpg);">

    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4">
            <img src="/assets/images/setildeunxperto.svg" alt="inspiración">
        </div>
    </div>
</div>
<!-- Fin Banner -->

<div *ngIf="staticContent.length > 1"
     id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li *ngFor="let sc of staticContent; let i = index"
            data-target="#carouselExampleIndicators"
            [attr.data-slide-to]="i"
            class="{{['active',''][cInt === 'a'+i ? 0:1]}}">
        </li>
    </ol>
    <div class="carousel-inner">
        <div *ngFor="let sc of staticContent; let c = index"
             class="carousel-item {{['active',''][cInt === 'a'+c ? 0:1]}}">
            <a href="{{ sc.staticContentVal3 }}">
            <img src="{{ sc.staticContentVal1 }}"
                 class="d-block w-100"
                 alt="{{ sc.staticContentVal2 }}">
            </a>
        </div>
    </div>
    <a (click)="pauseCarousel()"
       class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a (click)="pauseCarousel()"
       class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
