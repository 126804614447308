<ngx-spinner>
    <h3>Loading...</h3>
</ngx-spinner>

<app-nav-bar></app-nav-bar>
<app-section-header></app-section-header>

<div>
    <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
