import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IResponse, IRequest } from '../shared/models/forgotpassword';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  baseUrl = environment.apiUrl;

  constructor( private http: HttpClient ) { }

  validateTokenForgotPassword(hash: string) {
    return this.http.get(this.baseUrl + 'account/validatehash?hash=' + hash).pipe(
      map((response: IResponse) => {
        return response;
      })
    );
  }

  sendForgotPasswordRequest(request: IRequest) {
    return this.http.post(this.baseUrl + 'account/savepasswordbyhash', request).pipe(
      map((response: IResponse) => {
        return response;
      })
    );
  }

}
