<div class="container">
    <div class="row">
        <div class="col-12 pt-5">
            <h2 class="h1 text-center">testimonios de <span class="text-light-purple">clientes</span></h2>
        </div>
    </div>
</div>

<!-- init testimonials -->
<div id="testimonios" class="carousel slide text-center" data-ride="carousel">
    <div class="carousel-inner carousel-ver-mas">
        <div class="carousel-item active">

            <div class="container-fluid py-5">
                <div class="row align-items-start">
                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Francisco
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Agente Aduanero
                            </div>
                            <p>
                                Con lo aprendido en el curso ahora tengo mayor entendimiento de su
                                aplicación, adaptándolo al entorno o a las condiciones existentes,
                                esto a permitido mejoras sustanciales en los procesos.
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Carolina Barca Méndez
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Jefe de Logística y Distribución
                            </div>
                            <p>
                                Pude implementar Indicadores ó KPIs de Gestión Logística, lo cual me
                                favoreció mucho en mi trabajo diario
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Cristian Sanchez
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Jefe de Ventas Industriales
                            </div>
                            <p>
                                Muy bueno
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Alex Fernando Sulca Quispe
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Asistente Compras
                            </div>
                            <p>
                                Me ayudo en la visualización de los datos del proveedor realizando
                                una comparación fácil y sencilla con los datos en tiempo real y
                                actualizados.
                            </p></div></div>
                </div>
            </div>
        </div>

        <div class="carousel-item">
            <div class="container-fluid py-5">
                <div class="row align-items-start">

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Sara Montenegro Reyes
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Líder de Mejora Continua
                            </div>
                            <p>
                                Mejora kpi
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Daniel Zavaleta Melgar
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Jefe de Logística
                            </div>
                            <p>
                                El curso de técnicas estadísticas para la planificación de la
                                demanda me permito mejorar la exactitud de los pronósticos así como
                                a utilizar herramientas de gestión de grandes volúmenes de datos.
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Joseph Leiva Solano
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Planificador de Demanda
                            </div>
                            <p>
                                Me fue bien, pero llevarlos los KPIs en exel hace que sean
                                manipulables, con lo cual recomiendo algún sistema de logística.
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Diego Arturooli
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Asistente
                            </div>
                            <p>
                                Aporté con nuevos conocimientos que fueron de gran utilidad en su
                                aplicación.
                            </p></div></div>
                </div>
            </div>
        </div>

        <div class="carousel-item">
            <div class="container-fluid py-5">
                <div class="row align-items-start">
                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Cesar Carbajal Pachas
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Jefe de Planta
                            </div>
                            <p>
                                Comencé a ordenar y/o calificar por clientes, mes, ventas, de la
                                manera mas eficaz y entendible de tal forma que pude ver los
                                objetivos alcanzados por la empresa.
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Maria Felisa Jara Trujillo
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Analista Senior de Planillas
                            </div>
                            <p>
                                Desarrollé una presentación de vacaciones en base al curso.
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Felix Edison Bautista Meneses
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Coordinador
                            </div>
                            <p>
                                Power Bi.
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Willy Guiop Riva
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Jefe de R.R.H.H.
                            </div>
                            <p>
                                Gestión en base de información.
                            </p></div></div>
                </div>
            </div>
        </div>

        <div class="carousel-item">
            <div class="container-fluid py-5">
                <div class="row align-items-start">

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Luis Arenas Aparicio
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Gerente de Operaciones
                            </div>
                            <p>
                                Implementación de KPIs adicionales.
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Victor Escalante
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Auditor Operativo
                            </div>
                            <p>
                                Me ayudó a actualizarme y aportar ese conocimiento.
                            </p></div></div>

                    <div class="col-12 col-md-3">
                        <div class="autor p-5 text-white m-1">
                            <div class="text-center">
                                <div class="text-center m-4 h2">
                                    Mario Alberto Sánchez Melo
                                </div>
                            </div>
                            <div class="text-center text-light-grey m-4 c-shadow--">
                                Supervisor de Almacén
                            </div>
                            <p>
                                Excelente dinámica y didáctica muy útil para aplicar en el puesto
                                de trabajo.
                            </p></div></div>
                </div>
            </div>
        </div>

    </div>
    <a class="carousel-control-next-button"
       href="#testimonios" role="button" data-slide="next">
        <span class="c-light h2 m-2" aria-hidden="true">Ver más...</span>
        <span class="sr-only text-center">Next</span>
    </a>
</div>

<br/>
<br/>
<!-- ends testimonials -->

<!-- Texto -->
<div *ngIf="false" class="bloque-texto">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 py-5 my-4 text-center">
                <img src="/assets/images/video.png" alt="">
            </div>
            <div class="col-12 col-md-6 py-5 my-4">
                <h2 class="text-center mb-4">testimonios</h2>
                <p class="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus </p>
                <p class="text-center">
                    <a href="#" class="boton-corto">dejar testimonios</a>
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Fin Texto -->
