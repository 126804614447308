<!-- Banner -->
<div id="banner-page"
     class="container-fluid"
     style="background: url(/assets/images/graficos-banner.png) top, url(/assets/images/banner.jpg);">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3">Página no encontrada</h1>
        </div>
    </div>
</div>
<!-- Fin Banner -->
