import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IResponse, IRequest, ICourseProduct } from '../shared/models/product';
import { IProductCardHome, IResponseRelatedCourses } from '../shared/models/category';
import { ICResponse, ICRequest, IInsertComment } from '../shared/models/comment';
import { IRRequest, IRating } from '../shared/models/rating';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  baseUrl = environment.apiUrl;

  constructor( private http: HttpClient ) { }

  courseGet(request: IRequest) {
    return this.http.post<IResponse>(this.baseUrl + 'course/get', request).pipe(
      map((response: IResponse) => {
        return response;
      }, error => {
        console.log(error);
      })
    );
  }

  courseGetComments(request: ICRequest) {
    return this.http.post<ICResponse[]>(this.baseUrl + 'rating/getapproved', request).pipe(
      map((response: ICResponse[]) => {
        return response;
      }, error => {
        console.log(error);
      })
    );
  }

  courseGetRatings(request: IRRequest) {
    return this.http.post<IRating[]>(this.baseUrl + 'rating/getvalues', request).pipe(
      map((response: IRating[]) => {
        return response;
      }, error => {
        console.log(error);
      })
    );
  }

  sendComment(request: ICRequest) {
    return this.http.post(this.baseUrl + 'rating/registrar', request).pipe(
      map(() =>{
        return true;
      }, error => {
        console.log(error);
      })
    );
  }

  getRelatedCourses(request: IRequest) {
    return this.http.post<IResponseRelatedCourses>(
      this.baseUrl +
        'course/get-related',
      request);
  }
}
