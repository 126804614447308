<!-- header -->
<nav id="menu" class="navbar navbar-expand-lg  py-0 fixed-top">
    <a class="navbar-brand py-0" routerLink="/">
        <img src="/assets/images/c-experto-logo.png" alt="Harmony">
    </a>

    <div class="d-flex align-items-center">
        <a routerLink="/carrito" class="d-lg-none cart-menu mr-2">
            <i class="fas fa-shopping-cart"></i>
            <span id="item2" class="cart-counter box-shadows">
                0
            </span>
        </a>
        <button class="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
                <i class="fas fa-bars"></i>
                <i class="fas fa-times"></i>
            </span>
        </button>
    </div>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <a class="nav-link" routerLink="/">inicio</a>
            </li>
            <!-- <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> -->
                <!--     <a class="nav-link" href="javascript: void(0)" (click)="goToWe()">nosotros</a> -->
                <!-- </li> -->
            <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <a class="nav-link" routerLink="/categorias">categorías</a>
            </li>
            <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <a class="nav-link" routerLink="/cuenta/ser-instructor"><!--img src="/assets/images/c-experto-icono.png"--> c-instructor </a>
            </li>

            <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <a class="nav-link" href="#testimonios">testimonios</a>
            </li>
            <li class="nav-item dropdown">

                <a href="javascript: void(0)"
                   (click)="goTo('/carrito')"
                   class="nav-link dropdown-toggle increase-font-size"
                   id="navbarDropdown"
                   data-toggle="collapse"
                   data-target="#navbarSupportedContent"
                   aria-controls="navbarSupportedContent"
                   aria-expanded="false"
                   aria-label="Toggle navigation">
                    <i class="fa fa-shopping-cart mr-4"></i>
                    <span id="item1"
                          class="cart-counter light"
                          *ngIf="!(currentUser$ | async) || ((currentUser$ | async) && (currentUser$ | async).accountTypeID === 1)">
                        0
                    </span>
                    <span id="item0"
                          *ngIf="(currentUser$ | async) && (currentUser$ | async).accountTypeID !== 1"
                          class="cart-counter dark">
                        0
                    </span>
                </a>

                <div *ngIf="false" class="dropdown-menu border-0 p-4" aria-labelledby="navbarDropdown">
                    <!-- \\ with products -->
                    <div class="resumen-carrito pb-1 mb-2"><i class="fa fa-shopping-cart mr-1"></i> 3 cursos en el carrito </div>
                    <div class="d-flex">
                        <div class="w-75">Nombre del curso</div>
                        <div class="w-25 text-right">S/ 30.00</div>
                    </div>
                    <div class="d-flex">
                        <div class="w-75">Nombre del curso</div>
                        <div class="w-25 text-right">S/ 30.00</div>
                    </div>
                    <div class="d-flex">
                        <div class="w-75">Nombre del curso</div>
                        <div class="w-25 text-right">S/ 30.00</div>
                    </div>
                    <div class="text-center">
                        <a routerLink="/carrito" class="boton">comprar</a>
                    </div>
                    <!-- // with products -->

                </div>
            </li>

            <ng-container *ngIf="!(currentUser$ | async) || ((currentUser$ | async) && (currentUser$ | async).accountTypeID === 1)">
                <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <a data-toggle="collapse" routerLink="/cuenta/sesion" class="nav-link">iniciar sesión</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <a data-toggle="collapse" routerLink="/cuenta/registrar" class="nav-link">registrarse</a>
                </li>
            </ng-container>

            <ng-container *ngIf="(currentUser$ | async) && (currentUser$ | async).accountTypeID !== 1">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle increase-font-size" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-user mr-1"></i>
                        {{ (currentUser$ | async).displayName }}
                    </a>
                    <div class="dropdown-menu border-0 p-4" aria-labelledby="navbarDropdown">
                        <div class="resumen-carrito pb-2 mb-3">
                            Bienvenido {{(currentUser$ | async).displayName}}
                        </div>
                        <div class="d-flex">
                            <a href="javascript: void(0)" (click)="goTo('/carrito')" class="dropdown-item d-flex align-items-center py-2" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i class="fa fa-shopping-cart mr-3"></i> Ver Carrito
                            </a>
                        </div>
                        <div class="d-flex">
                            <a href="javascript: void(0)" (click)="goTo('/cuenta/ordenes')" class="dropdown-item d-flex align-items-center py-2" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i class="fa fa-history mr-3"></i> Ver Ordenes
                            </a>
                        </div>
                        <div class="d-flex">
                            <a *ngIf="(currentUser$ | async).isInstructor" href="javascript: void(0)" (click)="goTo('/cuenta/ventas-de-cursos')" class="dropdown-item d-flex align-items-center py-2" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i class="fa fa-dollar-sign mr-3"></i> Mis Ventas
                            </a>
                        </div>
                        <div class="text-center">
                            <a href="javascript: void(0)" (click)="goTo('/cuenta/mis-cursos')" class="boton" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i class="fa fa-pencil-alt mr-3"></i> Mis Cursos
                            </a>
                            <a href="javascript: void(0)" (click)="goTo('/cuenta/mi-cuenta')" class="boton" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i class="fa fa-user mr-3"></i> Mi Cuenta
                            </a>
                            <a *ngIf="(currentUser$ | async).isInstructor" href="javascript: void(0)" (click)="goTo('/cuenta/mi-cuenta-instructor')" class="boton" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i class="fa fa-edit mr-3"></i> Instructor
                            </a>
                            <a href="javascript: void(0)" (click)="logout()" class="boton" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i class="fa fa-sign-out-alt"></i> Cerrar sesión
                            </a>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
</nav>
<!-- Fin Header -->
