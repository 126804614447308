import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';
import { ICategory } from '../../shared/models/category';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CategoriesService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getCategories(token: string) {
    return this.http.get<ICategory[]>(
      this.apiUrl + 'category/getForHome'
      );
  }

  getAllCategories(category: string) {
    return this.http.post<ICategory[]>(
      this.apiUrl + 'category/getall', { 'category': category }
    ).pipe(
      map((response: ICategory[]) => {
        return response;
      }, error => {
        console.log(error);
      })
    );
  }

}
