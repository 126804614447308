import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IDeliveryMethod } from '../shared/models/deliveryMethod';
import { IOrderToCreate } from '../shared/models/order';
import { ICheckout, ICard, IBillingAddress } from '../shared/models/checkout';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  baseUrl = environment.apiUrl;

  constructor( private http: HttpClient ) { }

  basketPlaceOrderCC(values: any) {
    return this.http.post<any>(this.baseUrl + 'basket/placeorder/cc', values).pipe(
      map((response: any) => {
        return response;
      }, error => {
        console.log(error);
      })
    );
  }

  basketPlaceOrderWT(values: any) {
    return this.http.post<any>(this.baseUrl + 'basket/placeorder/wt', values).pipe(
      map((response: any) => {
        return response;
      }, error => {
        console.log(error);
      })
    );
  }

  basketPlaceOrderTotalZero(values: any) {
    return this.http.post<any>(this.baseUrl + 'basket/placeorder/ordertotalzero', values).pipe(
      map((response: any) => {
        return response;
      }, error => {
        console.log(error);
      })
    );
  }

}
