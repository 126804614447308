<!-- Banner -->
<div id="banner-page" class="container-fluid"
     style="background: url(/assets/images/graficos-banner.png) top,
            url(/assets/images/curso-detalle-banner.png);">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3">
                Estado de la orden
            </h1>
        </div>
    </div>
</div>
<!-- Fin Banner -->

<div *ngIf="!empty" class="row track-your-order">
    <div class="col-xs-12 col-sm-8">
        <h2 class="text-center mb-4 increase-text-size">Tus <span class="text-light-purple">datos</span></h2>
        <div class="order-details">
            <h3>Tu Orden ha sido completada.</h3>

            <p><strong>Nro. de Orden:         </strong> {{ orderNro }}           </p>
            <p><strong>Un email fue enviado a:</strong> {{ email }} </p>
            <p><strong>Tipo de Cuenta:         </strong> {{ accountType }}        </p>
            <p><strong>Fecha de Orden:        </strong> {{ dateCreated }}        </p>
            <p><strong>Hora de Orden:         </strong> {{ timeCreated }}        </p>
            <!-- <p><strong>Doc. Identidad:</strong> 46568787 </p> -->
            <p>
                <strong>Forma de Pago:        </strong>
                {{ paymentMethod }}
            </p>
           
            <p *ngIf="phoneNumber.length > 0">
                <strong>Teléfono: </strong> {{  phoneNumber }}
            </p>
            <p *ngIf="promoCode.length > 0">
                <strong>Cupón de Descuento: </strong> {{  promoCode }}
            </p>
        </div>
    </div>
</div>

<!-- tabla de productos -->
<div class="bloque-texto" *ngIf="!empty">
    <div class="container-fluid py-5">
        <div class="row">
            <div class="col-12 col-md-8 px-5 py-5">
                <div class="row text-center text-white align-items-center bg-purple">
                    <div class="col-6 col-md-8 py-3">
                        Productos
                    </div>
                    <div class="col-3 col-md-1 py-3">
                        Cantidad
                    </div>
                    <div class="col-3 col-md-3 py-3">
                        Precio
                    </div>
                </div>

                <div *ngIf="empty" id="banner-page" class="container-fluid">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-12 col-md-4 text-center">
                            <p class="display-4">No hay productos en su carrito</p>
                        </div>
                    </div>
                </div>
                <div class="row linea-carrito align-items-center text-dark"
                     *ngFor="let item of basketItems">
                    <div class="col-6 col-md-8 py-4 text-center text-md-left">
                        {{item.description}}
                    </div>
                    <div class="col-3 col-md-1 py-4 text-center">
                        {{item.quantity}}
                    </div>
                    <div class="col-3 col-md-3 py-4 text-center">
                        {{item.extendedPrice | currency : 'S/ '}}
                    </div>
                </div>

            </div>
            <div *ngIf="basketTotals" class="col-12 col-md-3 px-5 py-5">
                <h2 class=" mb-5">resumen</h2>
                <div class="row  text-dark pt-3 pb-2">
                    <div class="col-7">Sub-Total</div>
                    <div class="col text-right">{{ basketTotals.subTotal | currency : 'S/ ' }}</div>
                </div>
                <div class="row bg-white  text-dark pt-3 pb-2">
                    <div class="col-7">IGV</div>
                    <div class="col text-right">{{ basketTotals.taxTotal | currency : 'S/ ' }}</div>
                </div>
                <div *ngIf="basketTotals.promoCodeID > 1"
                     class="row bg-white text-dark pt-3 pb-2">
                    <strong class="col-7">Descuento</strong>
                    <div class="col text-right">{{ basketTotals.discountAmount | currency : 'S/ ' }}</div>
                </div>
                <div *ngIf="basketTotals.promoCodeID > 1"
                     class="row  text-white bg-purple mt-5 pt-3 pb-2">
                    <div class="col-7"></div>
                    <div class="col text-right tachado">{{ (basketTotals.total + basketTotals.discountAmount) | currency : 'S/ ' }}</div>
                </div>
                <div class="row  text-white bg-purple pt-3 pb-2">
                    <div class="col-7">Total</div>
                    <div class="col text-right">{{ basketTotals.total | currency : 'S/ ' }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- FIN tabla de productos -->
