import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IBasketItem, IAddToCart } from '../shared/models/basket';
import { IRequest, IResponse, ICategory, IProduct, ISubcategories } from '../shared/models/category';
import { CategoryService } from './category.service';
import { AccountService } from '../account/account.service';
import { BasketService } from '../basket/basket.service';
import { IBasketResponse } from '../shared/models/basket';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';

declare const LazyLoad: any;

declare const COUNTCART: any;

declare const ga: any;

declare const fbq: any;

declare const GAPPS_CODE: string;

function addToCartJSFB(prod) {
  fbq('track', 'AddToCart', {
    content_ids: [prod.variantID],
    content_type: 'product',
    value: prod.Price,
    currency: 'PEN'
  });
}

function addToCartJS(prod) {
  if (GAPPS_CODE !== '') {
    ga('create', GAPPS_CODE);
    ga('require', 'ec');
    ga('ec:addProduct', {
      'id': prod.variantID,
      'name': prod.name,
      'category': prod.category,
      'brand': 'c-xperto',
      'variant': prod.productID,
      'price': prod.Price,
      'quantity': 1 // c-xperto only 1 quantity ever
    });
    ga('ec:setAction', 'add');
    ga('send', 'event', 'UX', 'click', 'add to cart');
  }
}

function categoryInitLazyLoad() {
  const category = new LazyLoad({
    threshold: 0
  });
}

function count_cart_items_quantity(op,count) {
  COUNTCART.QUANTITY(op,count);
}

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  category: string;
  products: IProduct[];
  shortDescription: string;
  empty: boolean;
  addtocartObj: IAddToCart;
  basket: IBasketResponse;
  imageFileName: string;

  constructor(private activatedRoute: ActivatedRoute,
              private categoryService: CategoryService,
              private accountService: AccountService,
              private basketService: BasketService,
              private toastr: ToastrService,
              private router: Router,
              private titleService: Title,
              private meta: Meta) {

    const req = {
      'UserID': accountService.getSimpleUserID(),
      'CategoryID': activatedRoute.snapshot.params.CategoryID
    };

    categoryService.categoryGet(req).subscribe(response => {
      let category = '';
      let imageFileName = '';
      let shortDescription = '';
      const url = encodeURIComponent(window.location.href);
      if (response.status) {
        category = response.data.category;
        imageFileName = response.data.imageFileName;
        shortDescription = response.data.shortDescription;
      } else {
        category = 'Categoria no encontrada';
      }
      meta.updateTag({name:'og:title', content: category },`name='og:title'`);
      meta.updateTag({name:'og:url', content: url },`name='og:url'`);
      meta.updateTag({name:'og:image', content: imageFileName },`name='og:image'`);
      meta.updateTag({name:'description',content: shortDescription },`name='description'`);
    }, error => {
      console.log(error);
    });

  }

  fa: string;
  desire = [ 3181, 3179 ];

  ngOnInit() {
    window.scroll(0,0);
    this.getCategory();
    this.fa = 'far';
  }

  ratings(n: number): number[] {
    return [...Array(n).keys()];
  }

  clickerSwitchDesireArray(id) {
    let i;
    for(i = 0; i < this.desire.length; i++) {
      if(this.desire[i] === id) {
        let index = this.desire.indexOf(id);
        if (index > -1) {
          return this.desire.splice(index, 1);
        }
      }
    }
    return this.desire.push(id);
  }

  addToCart(productid: number, variantid: number, prod) {

    addToCartJSFB(prod);

    addToCartJS(prod);

    let bid = parseInt(localStorage.getItem('basket_id'), 10);

    if (isNaN(bid) || bid === null) {
      bid = 0;
    } else {
      bid = parseInt(localStorage.getItem('basket_id'), 10);
    }

    let token = parseInt(localStorage.getItem('token'), 10);

    if (isNaN(token) || token === null) {
      token = 0;
    } else {
      token = parseInt(localStorage.getItem('token'), 10);
    }

    this.addtocartObj = {
      'BasketID': bid,
      'ProductID': productid,
      'VariantID': variantid,
      'Quantity': 1,
      'UserID': token
    };

    this.basketService.addToBasket(this.addtocartObj).subscribe(response => {
      if(response.status) {
        this.basket = response;
        this.toastr.info(this.basket.message, '', {
          tapToDismiss: true
        });
        count_cart_items_quantity('+',null);
        localStorage.setItem('basket_id', this.basket.data.basketID.toString());
        localStorage.setItem('user_id', this.basket.data.userID.toString());
        localStorage.setItem('token', this.basket.data.userID.toString());
        localStorage.setItem('accountTypeID', this.basket.data.accountTypeID.toString());
      }
    }, error => {
      console.log(error);
    });
  }

  getCategory() {

    const req = {
      'UserID': this.accountService.getSimpleUserID(),
      'CategoryID': this.activatedRoute.snapshot.params.CategoryID
    };

    this.categoryService.categoryGet(req).subscribe(response => {

      if (response.status) {
        this.empty = false;
        this.category = response.data.category;
        this.imageFileName = response.data.imageFileName;
        if(this.imageFileName.includes('localhost')) {
          this.imageFileName = response.data.imageFileName.replace('///','/');
        }
        this.imageFileName = 'url(' +
          this.imageFileName
          + ')';
        this.titleService.setTitle(this.category + ' | c-xperto');
        this.shortDescription = response.data.shortDescription;
        this.products = response.data.products;
        setTimeout(categoryInitLazyLoad,500);
      } else {
        this.empty = true;
        this.category = 'Categoria no encontrada';
      }

    }, error => {
      console.log(error);
    });
  }

  goToProduct(url) {
    this.router.navigateByUrl(url);
  }

}
