import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IResponse, IRequest, ICourseProduct, IValidation } from '../shared/models/product';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  baseUrl = environment.apiUrl;

  constructor( private http: HttpClient ) { }

  courseGet(request: any) {
    return this.http.post<any>(this.baseUrl + 'course/getforstudent', request).pipe(
      map((response: IResponse) => {
        return response;
      }, (error: any) => {
        console.log(error);
      })
    );
  }

  courseNameExists(value: string) {
    return this.http.post<boolean>
      (
        this.baseUrl + 'coursecreation/nameexists',
        { 'Nombre': value }
      )
      .pipe(
        map((response: boolean) => {
          return response;
        }, (error: any) => {
          console.log(error);
        })
      );
  }

}
