<!-- Cursos -->
<div class="container-fluid px-0">
    <div class="row no-gutters">
        <div *ngFor="let product of products" class="col-12 curso">
            <a href="javascript: void(0)" (click)="goToProduct(product.url)">
                <img alt="{{product.name}}"
                     class="w-100 lazy img-border-top"
                     src="assets/images/curso-empty.png"
                     data-src="{{ product.imageFileName1 }}">
                <div class="curso-info p-2 text-center">
                    <h3>{{ product.name }}</h3>

                    <!-- \\ realprice template -->
                    <div *ngIf="product.realPrice !== product.price &&
                                product.price !== ''   &&
                                product.price !== null &&
                                product.realPrice !== '' &&
                                product.realPrice !== null"
                         class="precio">
                        <span class="actual mx-1">
                            {{ product.price | currency : 'S/ ' }}
                        </span>
                        <span class="antiguo mx-1">
                            <del>
                                {{ product.realPrice | currency : 'S/ ' }}
                            </del>
                        </span>
                    </div>
                    <div *ngIf="product.realPrice === product.price ||
                                product.price === ''   ||
                                product.price === null ||
                                product.realPrice === '' ||
                                product.realPrice === null"
                         class="precio">
                        <span class="actual mx-1">
                            {{ product.price === '' ? product.price : product.realPrice | currency : 'S/ ' }}
                        </span>
                    </div>
                    <!-- // realprice template -->

                </div>
            </a>
        </div>
    </div>
</div>
<!-- Fin Curso -->
