import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ISubChapter, IChapter } from '../shared/models/chapter';
import { CourseService } from './course.service';
import { AccountService } from '../account/account.service';
import { Router, ActivatedRoute } from '@angular/router';

declare let plusOneFinish: any;
declare const POSTS: any;
declare const TOOLTIP: any;

function postdata(url, method, senddata) {
  POSTS.POSTDATA(url, method, senddata);
}

function ariaexpanded(id,expand) {
  TOOLTIP.ARIAEXPANDED(id,expand);
}

function removeclass(id,thatclass) {
  document.querySelectorAll("[data-target='"+id+"']")[0].classList.remove(thatclass);
}

function addclass(id,thatclass) {
  document.querySelectorAll("[data-parent='"+id+"']")[0].classList.add(thatclass);
}

function run(song, video, link){

  let parent = link.parentElement;
  //Quita el active de todos los elementos de la lista
  let items = parent.parentElement.getElementsByTagName('li');
  for(let item in items) {
    if (items[item].classList) {
      items[item].classList.remove("is-active-play");
      break;
    }
  }
  //Agrega active a este elemento
  parent.classList.add("is-active-play");
  //Inicia la reproducción

  if(video === false) {
    return;
  }

  video.src = song;
  video.load();
  video.play();

  var videoPosition = document.getElementById("contenido").offsetTop;

  if(window.innerWidth <= 767) {
    window.scroll(0,(videoPosition - 45));
  } else {
    window.scroll(0,(videoPosition - 30));
  }
}

function resumeRun(song, video, link, objResume){

  ariaexpanded("#collapse"+objResume.lastChapterID,true);
  removeclass("#collapse"+objResume.lastChapterID,"collapsed");
  addclass("#accordion"+objResume.lastChapterID,"show");

  let parent = link.parentElement;
  //Quita el active de todos los elementos de la lista
  let items = parent.parentElement.getElementsByTagName('li');
  for(let item in items) {
    if (items[item].classList) {
      items[item].classList.remove("is-active-play");
      break;
    }
  }
  //Agrega active a este elemento
  parent.classList.add("is-active-play");
  //Inicia la reproducción
  video.src = song;
  video.load();
  video.currentTime = objResume.lastCurrentTime;
  video.play();

  var videoPosition = document.getElementById("contenido").offsetTop;

  if(window.innerWidth <= 767) {
    window.scroll(0,(videoPosition - 45));
  } else {
    window.scroll(0,(videoPosition - 30));
  }
}

function initResuming(obj,objResume) {

  let playlist: any;
  let tracksArray: HTMLCollection;
  // variable global example

  const el = document.querySelector('#resumen');
  let video = document.getElementById('videoplaylist') as any;
  const tracks = [];

  if(el) {
    for(let i = 0;i < parseInt(el.dataset.count, 10); i++) {
      playlist = document.getElementById('playlist' + i);
      tracksArray = playlist.getElementsByTagName('a');
      for (let p = 0; p < tracksArray.length; p++)
      {
        // only add videos pls
        if(tracksArray[p].getAttribute('href').includes(".mp4")) {
          tracks.push(tracksArray[p]);
        }
      }
    }
  }

  const player = new Plyr('#videoplaylist', {
    captions: { active: true, language: 'es' },
    controls: [
      'play-large',
      'rewind',
      'play',
      'fast-forward',
      'progress',
      'current-time',
      'mute',
      'volume',
      'captions',
      'settings',
      'fullscreen'
    ],
    invertTime: true,
    debug: false
  });

  //Cuenta los tracks
  for(let track in tracks) {
    let link = tracks[track];
    if(typeof link === "function" || typeof link === "number"){continue;}
    link.addEventListener('click', function(e) {
      e.preventDefault();
      let song = link.getAttribute('href');
      findCurrentVideoCourseID(song,obj,player,video);
      run(song, video, link);
    });
  }

  // RESUME COURSE objResume
  for(let track in tracks) {
    let link = tracks[track];
    let resume = parseInt(track);
    if(typeof link === "function" || typeof link === "number"){continue;}
    if(link.getAttribute('href') === objResume.lastProductChapterDetailLink) {
      let resumeTrackLink = tracks[resume];
      resumeRun(resumeTrackLink.getAttribute('href'), video, resumeTrackLink,objResume);
      findCurrentVideoCourseID(resumeTrackLink.getAttribute('href'),obj,player,video);
      break;
    }
  }

  //Agregamos evento para reproducir el siguiente items
  video.addEventListener('ended',function(e) {
    for(let track in tracks) {
      let link = tracks[track];
      let nextTrack = parseInt(track) + 1;
      let endTrack = parseInt(track);
      if(typeof link === "function" || typeof link === "number"){continue;}
      if(!this.src){this.src = tracks[0];}
      if(parseInt(track, 10) == (tracks.length - 1)){nextTrack = 0;}
      if(link.getAttribute('href') === this.src) {
        let nextLink = tracks[nextTrack];
        let endTrackLink = tracks[endTrack];
        run(nextLink.getAttribute('href'), video, nextLink);
        findCurrentVideoCourseID(nextLink.getAttribute('href'),obj,player,video);
        sendVideoIsFinish(endTrackLink.getAttribute('href'),obj,player,video);
        break;
      }
    }
  });

}

function rW(chapters) {
  let w = 0;
  for(let c = 0; c < chapters.length; c++) {
    for(let d = 0; d < chapters[c].detalle.length;d++) {
      if(chapters[c].detalle[d].isCompleted) {
        w++;
      }
    }
  }
  // watched totals
  return w;
}

function rT(chapters) {
  let t = 0;
  for(let c = 0; c < chapters.length; c++) {
    for(let d = 0; d < chapters[c].detalle.length;d++) {
        t++;
    }
  }
  // count subchapter videos
  return t;
}

function initVideo() {
  const playerEmpty = new Plyr('#videoplaylist', {
    captions: { active: true, language: 'es' },
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'captions',
      'settings',
      'fullscreen'
    ],
    debug: false
  });
}

declare const Plyr: any;
declare const document: any;
declare const API: string;
declare const FBAPPS: any;

function fbshare(w, h) {
  FBAPPS.SHARE(w, h);
}

function getcommentposition() {
  setTimeout(function() {
    window.scroll(0,(document.getElementById("inputComment").offsetTop - 30))
  }, 1000);
}

function sendVideoIsFinish(url,obj,p,v) {
  for(let c = 0; c < obj.length; c++) {
    for(let d = 0; d < obj[c].detalle.length; d++) {
      if(url === obj[c].detalle[d].productChapterDetailLink) {
        const requesturl = 'courseprogress/setcompleted?courseresourcesprogressid='+obj[c].detalle[d].courseResourcesProgressID;
        const completer = new XMLHttpRequest();
        completer.open("GET",API+requesturl,true);
        completer.send(null);
        if(document.getElementById('chk'+obj[c].detalle[d].courseResourcesProgressID).checked === false) {
          plusOneFinish = (plusOneFinish + 1);
        }
        document.getElementById('chk'+obj[c].detalle[d].courseResourcesProgressID).checked = true;
      }
    }
  }
}

function findCurrentVideoCourseID(activeVideoUrl,completeObj,p,v) {
  for(let c = 0; c < completeObj.length; c++) {
    for(let d = 0; d < completeObj[c].detalle.length; d++) {
      if(activeVideoUrl === completeObj[c].detalle[d].productChapterDetailLink) {
        ariaexpanded("#collapse"+completeObj[c].detalle[d].productChapterID,true);
        removeclass("#collapse"+completeObj[c].detalle[d].productChapterID,"collapsed");
        addclass("#accordion"+completeObj[c].detalle[d].productChapterID,"show");
        let id = completeObj[c].detalle[d].courseResourcesProgressID;
        // set title and description ;)
        document.getElementById("settitle").innerHTML =
          completeObj[c].detalle[d].productChapterDetailName
        ;
        document.getElementById("setdescription").innerHTML =
          completeObj[c].detalle[d].productChapterDetailDescription
        ;
        setInterval(function() {setCurrentTimeCourseProgressSeg(id,p.currentTime,p.playing,v)},15000);
        break;
      }
    }
  }
}

function initStarting(obj){

  let playlist: any;
  let tracksArray: HTMLCollection;
  // variable global example

  const el = document.querySelector('#resumen');
  let video = document.getElementById('videoplaylist') as any;
  const tracks = [];

  if(el) {
    for(let i = 0;i < parseInt(el.dataset.count, 10); i++) {
      playlist = document.getElementById('playlist' + i);
      tracksArray = playlist.getElementsByTagName('a');
      for (let p = 0; p < tracksArray.length; p++)
      {
        // only add videos pls
        if(tracksArray[p].getAttribute('href').includes(".mp4")) {
          tracks.push(tracksArray[p]);
        }
      }
    }
  }

  const player = new Plyr('#videoplaylist', {
    captions: { active: true, language: 'es' },
    controls: [
      'play-large',
      'rewind',
      'play',
      'fast-forward',
      'progress',
      'current-time',
      'mute',
      'volume',
      'captions',
      'settings',
      'fullscreen'
    ],
    invertTime: true,
    debug: false
  });

  let isPlayFirstTime = true;
  //Cuenta los tracks
  for(let track in tracks) {
    let link = tracks[track];
    if(typeof link === "function" || typeof link === "number"){continue;}
    link.addEventListener('click', function(e) {
      isPlayFirstTime = false;
      e.preventDefault();
      let song = link.getAttribute('href');
      findCurrentVideoCourseID(song,obj,player,video);
      run(song,video,link);
    });
  }

  //Agregamos evento para reproducir el siguiente items
  video.addEventListener('ended',function(e) {
    for(let track in tracks) {
      let link = tracks[track];
      let nextTrack = parseInt(track) + 1;
      let endTrack = parseInt(track);
      if(typeof link === "function" || typeof link === "number"){continue;}
      if(!this.src){this.src = tracks[0];}
      if(parseInt(track, 10) == (tracks.length - 1)){nextTrack = 0;}
      if(link.getAttribute('href') === this.src) {
        let nextLink = tracks[nextTrack];
        let endTrackLink = tracks[endTrack];
        run(nextLink.getAttribute('href'), video, nextLink);
        findCurrentVideoCourseID(nextLink.getAttribute('href'),obj,player,video);
        sendVideoIsFinish(endTrackLink.getAttribute('href'),obj,player,video);
        break;
      }
    }
  });

  // Escuchamos el evento play sólo por primera vez de reproduccion
  video.addEventListener('play',function() {
    if(isPlayFirstTime) {
      findCurrentVideoCourseID(tracks[0].getAttribute('href'),obj,player,video);
      run(false,false,tracks[0]);
      isPlayFirstTime = false;
    }
  });
}

function setCurrentTimeCourseProgressSeg(id,ctime,isPlay,v) {
  if(isPlay &&
     ctime > 0 &&
     typeof ctime !== "undefined") {
    const val = {
      "CourseResourcesProgressID": id,
      "CurrentTime": parseInt(ctime)
    };
    postdata(API + "courseprogress/setcurrenttime","POST",val);
  }
}

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  url: string;
  title: string;
  status: boolean;
  poster: string;
  totals: number;
  counter: number;
  watched: number;
  initLink: string;
  description: string;
  courseTitle: string;
  chapters: IChapter[];
  courseProgress: number;
  courseProgressPercentage: string;
  subChapterList: ISubChapter[] = [];
  isOrderCompleted: boolean;
  full: string;

  constructor(private router: Router,
              private courseService: CourseService,
              private activatedRoute: ActivatedRoute,
              private accountService: AccountService) { }

  ngOnInit() {
    window.scroll(0,0);
    this.courseGet();
    this.full='';
  }

  courseGet() {

    this.courseTitle = this.activatedRoute.snapshot.params.description;

    const request = {
      "productID": this.activatedRoute.snapshot.params.productID,
      "variantID": this.activatedRoute.snapshot.params.variantID,
      "orderItemID": this.activatedRoute.snapshot.params.orderItemID,
      "userID": this.accountService.getSimpleUserID()
    };

    this.courseService.courseGet(request).subscribe(response => {
      this.full = '';
      if(response.status === false) {
        this.courseTitle = 'No se encontró el recurso solicitado';
        this.status = false;
        return;
      }

      if(response.data.isOrderCompleted === false) {
        this.isOrderCompleted = response.data.isOrderCompleted;
        this.initLink = '/assets/images/empty-video.mp4';
        this.poster = response.data.imageFileName1 !== null ?
          response.data.imageFileName1 :
          '/assets/images/video-studend-1280x720.png'
        ;
        setTimeout(initVideo, 2000);
        this.title = 'El curso será habilitado despues de hacer el ' +
          ' pago';
        this.status = true;
        return;
      }

      this.status = response.status;
      this.isOrderCompleted = response.data.isOrderCompleted;

      if ('chapters' in response.data) {
        this.chapters = response.data.chapters;
        this.counter = response.data.chapters.length;
        this.poster = response.data.imageFileName1;
        this.url = location.origin + response.data.url;

        if ('detalle' in response.data.chapters[0] &&
          response.data.chapters[0].detalle.length !== 0) {
          this.title = response.data.chapters[0].detalle[0].productChapterDetailName;
          this.description = response.data.chapters[0].detalle[0].productChapterDetailDescription;
          this.initLink =
            response.data.chapters[0].detalle[0].productChapterDetailLink;
          const self = this;
          setInterval(function() { self.pieChartProgress(response.data.chapters); },1000);

          const chapters = response.data.chapters;

          if(typeof response.data.lastChapterID === "undefined" ||
             response.data.lastChapterID === 0) {
            const chapters = response.data.chapters;
            setTimeout(function() { initStarting(chapters) }, 2000);
          }
          if(response.data.lastChapterID > 0) {
            let lastUrl = '';
            for(let c = 0;c < chapters.length;c++) {
              if(chapters[c].productChapterID === response.data.lastChapterID) {
                for(let d = 0;d < chapters[c].detalle.length;d++) {
                  if(chapters[c].detalle[d].productChapterDetailID === response.data.lastChapterDetailID) {
                    lastUrl = chapters[c].detalle[d].productChapterDetailLink;
                    break;
                  }
                }
                break;
              }
            }
            const resume = {
              "lastChapterID":response.data.lastChapterID,
              "lastChapterDetailID":response.data.lastChapterDetailID,
              "lastCurrentTime":response.data.lastCurrentTime,
              "lastProductChapterDetailLink":lastUrl
            };
            setTimeout(function () {
              initResuming(chapters,resume);
            },123);
          }
          setTimeout(function () {
            self.full = ' 100%';
          },456);
        } else {
          this.title = 'Los videos serán publicados muy pronto';
          this.initLink = '/assets/images/empty-video.mp4';
          setTimeout(initVideo, 2000);
          this.status = true;
        }
      }
    }, error => {
      this.courseTitle = 'No se encontró el recurso solicitado';
      this.status = false;
      console.log(error);
    });
  }

  shows(title, description) {
    this.title = title;
    this.description = description;
  }

  goToCalification() {
    const url =
      '/articulo/' +
      this.activatedRoute.snapshot.params.productID +
      '/' +
      this.activatedRoute.snapshot.params.variantID +
      '/' +
      this.activatedRoute.snapshot.params.description;

    this.router.navigateByUrl(url);

    setTimeout(getcommentposition, 2000);
  }

  pieChartProgress(chapters) {
    // pieChartProgress Updater
    this.watched = rW(chapters);
    this.totals = rT(chapters);
    let plused = (this.watched + plusOneFinish);
    let percentage = ((plused * 100) / this.totals);
    this.courseProgressPercentage = 'conic-gradient(#b52a81 ' + percentage + '%, #c3c3c3 0%)';
  }

}
