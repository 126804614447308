<!-- \\ Banner Categorías -->
<div class="container">
    <div class="row">
        <div class="col-12 pt-5 pb-4">
            <h2 class="h1 text-center">nuestras <span class="text-light-purple">categorías</span></h2>
        </div>
    </div>
</div>
<!-- // Banner Categorías -->

<!-- \\ Categorias -->
<div>
    <div class="row categories">
        <!-- \\ category loop -->
        <div class="col-12 categorias px-0" *ngFor="let category of categories">
            <a href="javascript: void(0)"
               (click)="goToCategory(category.url)">
                <div class="nombre-categoria text-right h1 mb-0 py-4 text-white pr-4 pl-5">
                    {{ category.category }}
                </div>
                <img class="lazy"
                     src="/assets/images/categoria1-empty.png"
                     data-src="{{ category.imageFileName }}"
                     alt="{{ category.category }}">
            </a>
        </div>
        <!-- // category loop -->
    </div>
</div>
<!-- // Categorias -->
