<!-- Banner -->
<div id="banner-page"
     class="banner-top container-fluid"
     [ngStyle]="{ 'background' : imageFileName }">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3">{{ category }}</h1>
        </div>
    </div>
</div>
<!-- Fin Banner -->

<!-- Texto -->
<div *ngIf="false" class="container-fluid">
    <div class="row">
        <div class="col py-5">
            <p> {{ shortDescription }} </p>
        </div>
    </div>
</div>
<!-- FIn Texto -->

<!-- Filtro -->
<div *ngIf="false" class="container-fluid filtro py-3">
    <div class="row justify-content-end">
        <div class="col-12 col-md-3 my-3">
            <input type="search" placeholder="Buscar">
        </div>
        <div class="col-12 col-md-3 my-3">
            <select  id="">
                <option>Ordenar por</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
            </select>
        </div>
    </div>
</div>
<!-- Fin FIltro -->

<!-- Cursos -->
<div *ngIf="!empty" class="container-fluid px-0">
    <div class="row no-gutters">

        <div class="col-12 curso" *ngFor="let product of products">
            <a href="javascript: void(0)" (click)="goToProduct(product.url)">
                <img class="w-100 lazy"
                     src="/assets/images/curso-empty.png"
                     data-src="{{ product.imageFileName1 }}"
                     alt="{{ product.name }}">
                <div class="curso-info p-4 text-center">
                    <h3>{{ product.name }}</h3>
                    <!--
                    <div *ngIf="product.rating"
                         class="rating">
                        <i *ngFor="let i of ratings(product.Valoration)"
                           class="fas fa-star">
                        </i>
                        {{ product.Votes }} voto{{ product.Votes === 1 ? ' ' : 's' }}
                    </div>
                    <div *ngIf="product.teacherName"
                         class="profesor">
                        {{ product.teacherName }}
                    </div>
                    -->
                    <!-- \\ realprice template -->
                    <div *ngIf="product.realPrice !== product.price &&
                                product.price !== ''   &&
                                product.price !== null &&
                                product.realPrice !== '' &&
                                product.realPrice !== null"
                         class="precio">
                        <span class="actual mx-2">
                            {{ product.price | currency : 'S/ ' }}
                        </span>
                        <span class="antiguo mx-2">
                            <del>
                                {{ product.realPrice | currency : 'S/ ' }}
                            </del>
                        </span>
                    </div>
                    <div *ngIf="product.realPrice === product.price ||
                                product.price === ''   ||
                                product.price === null ||
                                product.realPrice === '' ||
                                product.realPrice === null"
                         class="precio">
                        <span class="actual mx-2">
                            {{ product.price === '' ? product.price : product.realPrice | currency : 'S/ ' }}
                        </span>
                    </div>
                    <!-- // realprice template -->

                </div>
            </a>
            
        </div>

    </div>
</div>
<!-- Fin Curso -->
