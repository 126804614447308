<!-- Texto -->
<div id="nosotros" class="bloque-texto">
    <div class="container">
        <div class="row justify-content-center py-5">
            <div class="col-12 col-md-7 py-5 my-5">
                <h2 class="text-center mb-4">sobre <span class="text-light-purple">nosotros</span></h2>
                <p class="text-center">
                    Empresa de capacitaci&oacute;n e-learning enfocada
                    en ofrecer cursos sumamente pr&aacute;cticos, de
                    aplicaci&oacute;n sencilla y eficiente dirigida a
                    profesionales mandos medios, jefes y gerentes de
                    empresas sobresalientes.
                </p>
                <p><br></p>
                <p>Las categor&iacute;as de cursos est&aacute;n dirigidos a:</p>
                <p>1. Supply chain - Log&iacute;stica</p>
                <p>2. Comercial</p>
                <p>3. Ventas</p>
                <p>4. Finanzas</p>
                <p>5. Control de gesti&oacute;n</p>
                <p>6. Recursos humanos</p>
                <!-- <p class="text-center"> -->
                <!--     <a href="#" class="boton-corto">ver más</a> -->
                <!-- </p> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin Texto -->
