import { Component, OnInit } from '@angular/core';
import { CategoriesService } from '../home/categories/categories.service';
import { ICategory } from '../shared/models/category';
import { Router, ActivatedRoute } from '@angular/router';

declare const LazyLoad: any;
function categoriesInitLazyLoad() {
  const categories = new LazyLoad({
    threshold: 0
  });
}

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: ICategory[];

  constructor(private categoriesService: CategoriesService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    window.scroll(0,0);
    let token = localStorage.getItem('token');
    if (token === null) {
      token =  '0';
    }

    this.categoriesService.getCategories(token).subscribe(response => {
      this.categories = response;
      setTimeout(categoriesInitLazyLoad,500);
    }, error => {
      console.log(error);
    });
  }

}
