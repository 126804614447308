import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IBasket, IBasketItem } from '../../models/basket';
import { IOrderItem } from '../../models/order';
import { ToastrService } from 'ngx-toastr';
import { BasketService } from '../../../basket/basket.service';

@Component({
  selector: 'app-basket-summary',
  templateUrl: './basket-summary.component.html',
  styleUrls: ['./basket-summary.component.scss']
})
export class BasketSummaryComponent implements OnInit {
  @Output() decrement: EventEmitter<IBasketItem> = new EventEmitter<IBasketItem>();
  @Output() increment: EventEmitter<IBasketItem> = new EventEmitter<IBasketItem>();
  @Output() remove: EventEmitter<IBasketItem> = new EventEmitter<IBasketItem>();
  @Input() isBasket = true;
  @Input() items: IBasketItem[] | IOrderItem[] = [];
  @Input() isOrder = false;

  basketItems: IBasketItem[];

  constructor(private toastr: ToastrService,
              private basketService: BasketService) { }

  ngOnInit() {
    window.scroll(0,0);
  }

  removeItem(item: IBasketItem) {
    let i = 0;
    const newitems = [];

    this.toastr.info('🗑 ' + item.description, 'Removiendo del Carrito', {
      tapToDismiss: true
    });

    const data = {
      'BasketID': item.basketID,
      'BasketItemID': item.basketItemID
    };

    this.basketService.delItem(data).subscribe(response => {
      console.log(response.data.items);
      this.basketItems = response.data.items;
    }, error => {
      console.log(error);
    });


    for (i = 0; i < this.items.length; i++) {
      if (this.items[i] !== item) {
        newitems.push(this.items[i]);
      }
    }
    this.items = newitems;
  }

}
