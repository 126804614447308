import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router'
import { ForgotPasswordService } from './forgot-password.service';

declare const ll: any;

function lazyLoading() {
  ll.update();
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  messages: string[];
  fptype: string;
  status: boolean;
  message: string;

  constructor(private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private forgotPasswordService: ForgotPasswordService) { }

  ngOnInit() {
    setTimeout(lazyLoading, 500);
    this.createForgotPasswordFrom();
    this.messages = [];
    this.fptype = 'password';
    this.forgotPasswordService.validateTokenForgotPassword(
      this.activatedRoute.snapshot.params.hash
    ).subscribe(response => {
      this.status = response.status;
      this.message = response.message;
    });
  }

  createForgotPasswordFrom() {
    this.forgotPasswordForm = this.fb.group({
      displayPassword: new FormControl('', Validators.required),
      repeatDisplayPassword: new FormControl('', Validators.required),
      token: this.activatedRoute.snapshot.params.token
    });
  }

  onSubmit() {
    this.messages = [];

    if(typeof this.forgotPasswordForm.value.displayPassword === 'undefined' ||
       this.forgotPasswordForm.value.displayPassword === '' ||
       this.forgotPasswordForm.value.displayPassword === null ||
       this.forgotPasswordForm.value.displayPassword.length <= 6) {
      this.messages.push('Debe indicar su contraseña (mínimo 7 caracteres)');
      this.toastr.error('Debe indicar su contraseña (mínimo 7 caracteres)', 'Contraseña', {
        tapToDismiss: true
      });
      return;
    }

    if(typeof this.forgotPasswordForm.value.repeatDisplayPassword === 'undefined' ||
       this.forgotPasswordForm.value.repeatDisplayPassword === '' ||
       this.forgotPasswordForm.value.repeatDisplayPassword === null ||
       this.forgotPasswordForm.value.repeatDisplayPassword.length <= 6) {
      this.messages.push('Debe indicar repetir contraseña (mínimo 7 caracteres)');
      this.toastr.error('Debe indicar repetir contraseña (mínimo 7 caracteres)', 'Contraseña', {
        tapToDismiss: true
      });
      return;
    }

    if(this.forgotPasswordForm.value.displayPassword !==
       this.forgotPasswordForm.value.repeatDisplayPassword) {
      this.messages.push('Contraseña y repetir contraseña deben coincidir');
      this.toastr.error('Contraseña y repetir contraseña deben coincidir', 'Contraseña', {
        tapToDismiss: true
      });
    }

    const request = {
      "Password": this.forgotPasswordForm.value.displayPassword,
      "Hash": this.activatedRoute.snapshot.params.hash
    };

    this.forgotPasswordService.sendForgotPasswordRequest(request)
      .subscribe(response => {
        this.toastr.info('Clave acutalizada', 'Olvidé mi contraseña', {
          tapToDismiss: true
        });
        setTimeout(this.redirectLogin,1000);
      }, error => {
        console.log(error);
      });
  }

  switchHidenDisplayPassword(fptype) {
    console.log(fptype);
    if(fptype === 'text') {
      this.fptype = 'password';
    } else {
      this.fptype = 'text'
    }
  }

  redirectLogin() {
    this.router.navigateByUrl('/cuenta/sesion');
  }

}
