<ng-container>
    <div *ngIf="items.length === 0" id="banner-page" class="container-fluid">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-12 col-md-4 text-center">
                <p class="display-4">No hay productos en su carrito</p>
            </div>
        </div>
    </div>
    <div class="row linea-carrito align-items-center text-dark"
         *ngFor="let item of items">
        <div class="col-12 col-md-7 py-4 text-center text-md-left">
            {{item.description}}
        </div>
        <div class="col-12 col-md-1 py-4 text-center">
            {{item.quantity}}
        </div>
        <div class="col-12 col-md-3 py-4 text-center">
            {{item.extendedPrice | currency : 'S/ '}}
        </div>
        <div class="col-12 col-md-1 py-4 text-center">
            <a href="javascript: void(0)"
               (click)="removeItem(item)">
                🗑
            </a>
        </div>
    </div>
</ng-container>
