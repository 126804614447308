import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { IBasketResponse } from '../shared/models/basket';
import { IProduct } from '../shared/models/product';
import { IAddToCart, IRequestCart, IRemoveItem } from '../shared/models/basket';
import { IResponse, IRequest } from '../shared/models/forgotpassword';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  baseUrl = environment.apiUrl;
  private basketSource = new BehaviorSubject<IBasketResponse>(null);
  basket$ = this.basketSource.asObservable();
  shipping = 0;

  constructor(private http: HttpClient) { }

  addToBasket(data: IAddToCart) {
    return this.http.post<IBasketResponse>(
      this.baseUrl +
        'basket/additem', data
    );
  }

  addItem(item: IAddToCart) {
    return this.http.post(this.baseUrl + 'basket/additem', item)
    .subscribe((response: IBasketResponse) => {
      this.basketSource.next(response);
    }, error => {
      console.log(error);
    });
  }

  delItem(item: IRemoveItem) {
    return this.http.post<IBasketResponse>(
      this.baseUrl +
        'basket/removeitem', item
    );
  }

  getFull(item: IRequestCart) {
    return this.http.post<IBasketResponse>(
      this.baseUrl +
        'basket/getfull', item
    );
  }

  applyPromoCode(id,pc) {
    return this.http.post<IBasketResponse>(this.baseUrl+'basket/promocode/apply',{'BasketID':id,'promoCode':pc});
  }

  getStatus(item: IRequestCart) {
    return this.http.post<IBasketResponse>(
      this.baseUrl +'basket/validate/status',
      item
    );
  }

  addItemToBasket(item: IProduct, Quantity = 1) {
    console.log(item, Quantity);
  }

  getPayUSecurity() {
    console.log('api getPayUSecurity');

    return this.http.get(this.baseUrl + 'basket/getpaysecurity').pipe(
      map((response: IResponse) => {
        return response;
      })
    );
  }

}
