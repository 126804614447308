import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CoursesService } from './courses.service';
import { IProductCardHome, ICategoryCardHome } from '../../shared/models/category';
import { IAddToCart, IBasketResponse } from '../../shared/models/basket';
import { BasketService } from '../../basket/basket.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

declare const ll: any;

declare const document: any;

declare const ga: any;

declare const fbq: any;

declare const GAPPS_CODE: string;

declare const FAPPS_CODE: string;

declare const COUNTCART: any;

function count_cart_items_quantity(op,count) {
  COUNTCART.QUANTITY(op,count);
}

function lazyLoading() {
  ll.update();
}

function addToCartJSFB(prod) {
  fbq('track', 'AddToCart', {
    content_ids: [prod.variantID],
    content_type: 'product',
    value: prod.Price,
    currency: 'PEN'
  });
}

function addToCartJS(prod) {
  if (GAPPS_CODE !== '') {
    ga('create', GAPPS_CODE);
    ga('require', 'ec');
    ga('ec:addProduct', {
      'id': prod.variantID,
      'name': prod.name,
      'category': prod.category,
      'brand': 'c-xperto',
      'variant': prod.productID,
      'price': prod.Price,
      'quantity': 1 // c-xperto only 1 quantity ever
    });
    ga('ec:setAction', 'add');
    ga('send', 'event', 'UX', 'click', 'add to cart');
  }
}

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  categories: ICategoryCardHome[];
  addtocartObj: IAddToCart;
  basket: IBasketResponse;
  products: IProductCardHome[];

  constructor(private coursesService: CoursesService,
              private basketService: BasketService,
              private toastr: ToastrService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  fa: string;
  desire = [];

  ngOnInit() {
    window.scroll(0,0);

    let token = localStorage.getItem('token');
    if (token === null) {
      token =  '0';
    }

    this.coursesService.getCoursesHome(token).subscribe(response => {

      this.categories = response;
      let i = 0;
      let bump = [];
      for(i = 0; i < response.length; i++) {
        let o = 0;
        for(o = 0; o < this.categories[i].products.length;o++) {
          bump.push(response[i].products[o]);
        }
      }

      this.products = bump;
      setTimeout(lazyLoading,500);
    }, error => {
      console.log(error);
    });

    this.fa = 'far';
  }

  clickerSwitchDesireArray(id) {
    let i;
    for(i = 0; i < this.desire.length; i++) {
      if(this.desire[i] === id) {
        let index = this.desire.indexOf(id);
        if (index > -1) {
          return this.desire.splice(index, 1);
        }
      }
    }
    return this.desire.push(id);
  }

  addToCart(productid: number, variantid: number, prod) {

    addToCartJSFB(prod);

    addToCartJS(prod);

    let bid = parseInt(localStorage.getItem('basket_id'), 10);

    if (isNaN(bid) || bid === null) {
      bid = 0;
    } else {
      bid = parseInt(localStorage.getItem('basket_id'), 10);
    }

    let token = parseInt(localStorage.getItem('token'), 10);

    if (isNaN(token) || token === null) {
      token = 0;
    } else {
      token = parseInt(localStorage.getItem('token'), 10);
    }

    this.addtocartObj = {
      'BasketID': bid,
      'ProductID': productid,
      'VariantID': variantid,
      'Quantity': 1,
      'UserID': token
    };

    this.basketService.addToBasket(this.addtocartObj).subscribe(response => {
      if(response.status) {
        this.basket = response;
        this.toastr.info(this.basket.message, '', {
                           tapToDismiss: true
                         });
        count_cart_items_quantity('+',null);
        localStorage.setItem('basket_id', this.basket.data.basketID.toString());
        localStorage.setItem('user_id', this.basket.data.userID.toString());
        localStorage.setItem('token', this.basket.data.userID.toString());
        localStorage.setItem('accountTypeID', this.basket.data.accountTypeID.toString());
      }
    }, error => {
      console.log(error);
    });
  }

  ratings(n: number): number[] {
    return [...Array(n).keys()];
  }

  goToProduct(url) {
    this.router.navigateByUrl(url);
  }

}
