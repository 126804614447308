<!-- Footer -->
<footer class="container-fluid bg-dark text-center text-md-left">
    <div class="row py-4">
        <div class="col-12 col-md-3 py-4">
            <ul class="p-0 m-0">
                <li><a href="javascript: void(0)" (click)="goToWe()">nosotros</a></li>
                <li><a href="javascript: void(0)" (click)="goTo('/categorias')">categorias</a></li>
            </ul>
        </div>
        <div class="col-12 col-md-3 py-4">
            <ul class="p-0 m-0">
                <li><a target="_new" href="/doc/TERMINOSYCONDICIONES-TERMINOSDEUSO.pdf">políticas de privacidad</a></li>
                <li><a target="_new" href="/doc/Preguntasfrecuentes.pdf">preguntas frecuentes</a></li>
            </ul>
        </div>
        <div class="col-12 col-md-3 py-4">
            <ul class="p-0 m-0">
                <li><a target="_new" href="https://reclamaciones.c-xperto.com/">libro de reclamación</a></li>
                <li><a href="mailto:soporte@c-xperto.com">soporte@c-xperto.com</a></li>
            </ul>
        </div>
        <div class="col-12 col-md-3 py-4 d-flex align-items-center">
            <div class="w-100 text-center">
                <a href="https://www.facebook.com/C-xperto-100766094983091" target="_new"><i class="mx-1 fab fa-facebook-square"></i></a>
                <!-- <a href="https://twitter.com/" target="_new"><i class="mx-1 fab fa-twitter-square"></i></a> -->
                <a href="https://www.linkedin.com/company/c-experto" target="_new"><i class="mx-1 fab fa-linkedin"></i></a>
                <!-- <a href="https://www.instagram.com/" target="_new"><i class="mx-1 fab fa-instagram"></i></a> -->
            </div>
        </div>
    </div>

    <div id="sthoverbuttonsWhatsApp" class="sthoverbuttonsWhatsApp-pos-right">
        <a href='https://api.whatsapp.com/send?phone=51948911682' rel="external" target="_blank">
            <div id="sthoverbuttonsWhatsApp-background" class="sthoverbuttonsWhatsApp-background-r">
                <div id="sthoverbuttonsWhatsApp-top" class="sthoverbuttonsWhatsApp-top-r">&nbsp;</div>
                <div id="sthoverbuttonsWhatsApp-shade" class="sthoverbuttonsWhatsApp-shade-r">&nbsp;</div>
                <div id="sthoverbuttonsWhatsApp-bottom" class="sthoverbuttonsWhatsApp-bottom-r">&nbsp;</div>
            </div>
            <div id="sthoverbuttonsWhatsAppMain" class="sthoverbuttonsWhatsAppMain-r">
                <div class="sthoverbuttonsWhatsApp-label">
                    <svg class="whatsappcontainer"
                         data-bind="attr: { alt: showAs,viewBox: svgViewBox,title: showAs }, css : { 'wh-messenger-icon' : true }, html: svgPath"
                         alt="WhatsApp"
                         viewBox="0 0 32 32"
                         title="WhatsApp"
                         class="wh-messenger-icon"
                         xmlns:xlink="http://www.w3.org/1999/xlink"
                         xmlns="http://www.w3.org/2000/svg">
			<path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                              fill-rule="evenodd"
                              fill="white">
                        </path>
		    </svg>
                </div>
            </div>
        </a>
    </div>

</footer>
<div class="container-fluid text-dark creditos">
    <div class="row">
        <div class="col-12 col-md-6 text-center text-md-left py-4">
            <img src="/assets/images/c-experto-logo-footer.png" alt="c-experto">
        </div>
        <div class="col-12 col-md-6 text-center text-md-right py-4">
            Powered by <a href="https://kfgestiondigital.com/" target="_new">KF Gestión Digital</a><br/>
            Build: {{environment.VERSION}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
    </div>
</div>
<!-- Fin Footer -->
