import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { BusyService } from 'src/app/core/services/busy.service';
import { ToastrService } from 'ngx-toastr';
import { IBasketItem, IAddToCart, IBasketResponse } from '../shared/models/basket';
import { ICourseProduct, IResponse } from '../shared/models/product';
import { BasketService } from '../basket/basket.service';
import { ArticleService } from './article.service';
import { IAimdes } from '../shared/models/aimdes';
import { IYouWillLearn } from '../shared/models/youWillLearn';
import { IDeviceRequirement } from '../shared/models/deviceRequirement';
import { IChapter, IDetalle } from '../shared/models/chapter';
import { AccountService } from '../account/account.service';
import { CoursesService } from '../home/courses/courses.service';
import { IProductCardHome, ICategoryCardHome } from '../shared/models/category';
import { ICResponse, ICRequest, IInsertComment } from '../shared/models/comment';
import { IRRequest, IRating } from '../shared/models/rating';
import { Meta, Title } from '@angular/platform-browser';
import { MetafrenzyService } from 'ngx-metafrenzy';
import { IInstructorLoginResponse } from '../shared/models/user';
import { IInstructorLogin } from '../shared/models/user';


declare const ll: any;

declare const document: any;

declare const ga: any;

declare const fbq: any;

declare const GAPPS_CODE: string;

declare const FBAPPS: any;

declare const window: any;

declare const COUNTCART: any;

function fbshare(w, h) {
  FBAPPS.SHARE(w, h);
}

function fbismobile() {
  return FBAPPS.ISMOBILE();
}

function lazyLoading() {
  ll.update();
}

function addToCartJSFB(prod) {
  fbq('track', 'AddToCart', {
    content_ids: [prod.variantID],
    content_type: 'product',
    value: prod.Price,
    currency: 'PEN'
  });
}

function viewContentJSFB(prod) {
  fbq('track', 'ViewContent', {
    content_ids: [prod.variantID],
    content_type: 'product',
    value: prod.Price,
    currency: 'PEN'
  });
}

function pageViewJS(prod) {
  if (GAPPS_CODE !== '') {
    ga('create', GAPPS_CODE);
    ga('require', 'ec');
    ga('ec:addProduct', {
      'id' : prod.productID,
      'name' : prod.name,
      'category' : prod.category,
      'brand' : 'c-xperto',
      'variant' : prod.variantID
    });
    ga('ec:setAction', 'detail');
    ga('send', 'pageview');
  }
}

function addToCartJS(prod) {
  if (GAPPS_CODE !== '') {
    ga('create', GAPPS_CODE);
    ga('require', 'ec');
    ga('ec:addProduct', {
      'id': prod.variantID,
      'name': prod.name,
      'category': prod.category,
      'brand': 'c-xperto',
      'variant': prod.productID,
      'price': prod.Price,
      'quantity': 1 // c-xperto only 1 quantity ever
    });
    ga('ec:setAction', 'add');
    ga('send', 'event', 'UX', 'click', 'add to cart');
  }
}

declare let Plyr: any;

function run(song, video){
  if(video === false) {
    return;
  }
  video.src = song;
  video.load();
}

function initVideo(song) {
  const intro = new Plyr('#player', {
    captions: { active: true, language: 'es' },
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'captions',
      'settings',
      'fullscreen'
    ],
    invertTime: true,
    debug: false
  });

  let vintro = document.getElementById('player') as any;
  run(song, vintro);
}

function count_cart_items_quantity(op,count) {
  COUNTCART.QUANTITY(op,count);
}

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  addtocartObj: IAddToCart;
  basket: IBasketResponse;
  response: IResponse;
  product: ICourseProduct;
  name: string;
  aimdes: IAimdes[];
  youWillLearn: IYouWillLearn[];
  deviceRequirements: IDeviceRequirement[];
  chapters: IChapter[];
  realPrice: number;
  price: number;
  valoration: number;
  votes: number;
  category: string;
  courseLevel: string;
  code: string;
  description: string;
  productID: number;
  variantID: number;
  enabled: boolean;
  empty: boolean;
  sourceUrl: string;
  categories: ICategoryCardHome[];
  RelatedCourses: IProductCardHome[];
  DrivenCourses: IProductCardHome[];
  MoreValorations: IRating[];
  commentProducstList: ICResponse[];
  hideSendComment: boolean;
  prod: ICourseProduct;
  poster: string;
  url: string;
  commentMsgButton: string;
  selection: number;
  instructor: IInstructorLogin;
  instructorUserID: number;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private basketService: BasketService,
              private articleService: ArticleService,
              private accountService: AccountService,
              private busyService: BusyService,
              private toastr: ToastrService,
              private coursesService: CoursesService,
              private titleService: Title,
              private meta: Meta,
              private readonly metafrenzyService: MetafrenzyService) {

    const request = {
      "productID": activatedRoute.snapshot.params.ProductID,
      "variantID": activatedRoute.snapshot.params.VariantID,
      "userID": accountService.getSimpleUserID()
    };

    articleService.courseGet(request).subscribe(response => {
      if (response.status) {
        // productID = response.data.productID;
        // variantID = response.data.variantID;
        const url = encodeURIComponent(window.location.href);
        try {
          metafrenzyService.setMetaTag('og:title',response.data.name);
          metafrenzyService.setMetaTag('og:url',url);
          metafrenzyService.setMetaTag('og:image',response.data.imageFileName1);
          metafrenzyService.setMetaTag('description',response.data.descriptionSEO);
          metafrenzyService.setMetaTag('og:video',response.data.initialVideoLink);
          metafrenzyService.setMetaTag('og:video:secure_url',response.data.initialVideoLink);
          metafrenzyService.setMetaTag('og:video:type','video/mp4')
          metafrenzyService.setMetaTag('og:video:width','400');
          metafrenzyService.setMetaTag('og:video:height','300');
        } catch(e) {
          console.log('error header catch');
        }
      }
    }, error => {
      console.log(error);
    });
  }

  start1 = false;
  start2 = false;
  start3 = false;
  start4 = false;
  start5 = false;
  valorationMsg = '';
  userLogged: boolean;

  desire = [ 3181, 3179 ];

  fa: string;

  currentuser = {
    token: localStorage.getItem('token'),
    Comment: '',
    CommentUserImage: 'images/user.jpg',
    DislayName: 'Enmanuel Saravia',
    Valoration: 0
  };

  ngOnInit() {
    window.scroll(0,0);
    this.sourceUrl = '';
    this.fa = 'far';
    this.getCurrentUser();
    this.courseGet();
    this.getComments();
    this.courseGetRatings();
    this.hideSendComment = false;
    this.getRelatedCourses();
    this.commentMsgButton = 'Calificar o comentar';
    this.selection = 0;
  }

  ratings(n: number): number[] {
    return [...Array(n).keys()];
  }

  clicker(fa: string) {
    if (fa === 'far') {
      this.fa = 'fas';
    }
    if (fa === 'fas') {
      this.fa = 'far';
    }
  }

  daysBeforeToString(days) {
    if (days === 0 || days === 1) {
      return 'hoy';
    } else if (days < 8) {
      days = days === 1 ? 1 : days;
      return 'hace ' + Math.trunc(days) + ' días';
    } else if (days < 29) {
      let weeks = (days / 7);
      const plural = weeks === 0 || weeks === 1 ? '' : 's';
      weeks = weeks === 0 || weeks === 1 ? 1 : weeks;
      return 'hace ' + Math.trunc(weeks) + ' semana' + plural + ' aprox';
    } else if (days < 366) {
      const weeks = (days / 30);
      let month = Math.trunc(weeks);
      const plural = Math.trunc(month) === 1 || Math.trunc(month) === 0 ? '' : 'es';
      month = Math.trunc(month) === 0 || Math.trunc(month) === 1 ? 1 : Math.trunc(month);
      return 'hace ' + month + ' mes' + plural + ' aprox';
    } else if (days > 367) {
      let year = (days / 365);
      const plural = Math.trunc(year) === 1 || Math.trunc(year) === 0 ? '' : 's';
      year = Math.trunc(year) === 1 || Math.trunc(year) === 0 ? 1 : Math.trunc(year);
      return 'hace ' + year + ' año' + plural + ' aprox';
    }
  }

  clickerSwitchDesireArray(id) {
    let i;
    for (i = 0; i < this.desire.length; i++) {
      if (this.desire[i] === id) {
        const index = this.desire.indexOf(id);
        if (index > -1) {
          return this.desire.splice(index, 1);
        }
      }
    }
    return this.desire.push(id);
  }

  getCurrentUser() {
    const token = localStorage.getItem('token');
    if (token) {
      this.userLogged = true;
    } else {
      this.userLogged = false;
    }
  }

  starMourseOver(starOption) {
    if (starOption === 1) {
      this.start1 = true;
      this.start2 = false;
      this.start3 = false;
      this.start4 = false;
      this.start5 = false;
    } else if (starOption === 2) {
      this.start1 = true;
      this.start2 = true;
      this.start3 = false;
      this.start4 = false;
      this.start5 = false;
    } else if (starOption === 3) {
      this.start1 = true;
      this.start2 = true;
      this.start3 = true;
      this.start4 = false;
      this.start5 = false;
    } else if (starOption === 4) {
      this.start1 = true;
      this.start2 = true;
      this.start3 = true;
      this.start4 = true;
      this.start5 = false;
    } else if (starOption === 5) {
      this.start1 = true;
      this.start2 = true;
      this.start3 = true;
      this.start4 = true;
      this.start5 = true;
    }
  }

  sendCalification(userCalification) {
    const comment = (<HTMLInputElement>document.getElementById('comment'));
    if (comment.value.trim() === '') {
      this.commentMsgButton = "Sólo calificar";
    } else {
      this.commentMsgButton = "Calificar y Comentar";
      this.currentuser.Comment = comment.value.trim();
    }
    this.currentuser.Valoration = userCalification;
    this.valorationMsg = 'Calificarás el curso con ' + userCalification + ' estrellas';
    this.toastr.warning(this.valorationMsg, '', {
      tapToDismiss: true
    });
  }

  sendComment() {
    const comment =
      (<HTMLInputElement>document.getElementById('comment'));

    let go = false;

    if (comment.value.trim() !== '') {
      go = true;
      this.currentuser.Comment = comment.value.trim();
    }

    if (this.currentuser.Valoration === 0 && go === false) {
      this.toastr.warning('Debe calificar o comentar.', '', {
        tapToDismiss: true
      });
      return;
    }

    const request = {
      "ProductID": this.activatedRoute.snapshot.params.ProductID,
      "VariantID": this.activatedRoute.snapshot.params.VariantID,
      "UserID": this.accountService.getSimpleUserID(),
      "Rating": this.currentuser.Valoration,
      "Comment": this.currentuser.Comment
    };

    if(this.accountService.getSimpleUserID() === 0) {
      this.router.navigateByUrl('/cuenta/sesion?pid=' +
                                this.activatedRoute.snapshot.params.ProductID +
                                '&vid=' +
                                this.activatedRoute.snapshot.params.VariantID +
                                '&n=' +
                                this.activatedRoute.snapshot.params.Name
                               );
      return;
    }

    this.articleService.sendComment(request).subscribe(response => {
      this.toastr.warning('Gracias.', '', {
        tapToDismiss: true
        
      });
      this.courseGetRatings();
      this.hideSendComment = true;
    }, error => {
      console.log(error);
    });

  }

  addToCart(productid: number,
            variantid: number) {

    let bid = parseInt(localStorage.getItem('basket_id'), 10);

    if (isNaN(bid) || bid === null) {
      bid = 0;
    } else {
      bid = parseInt(localStorage.getItem('basket_id'), 10);
    }

    let token = parseInt(localStorage.getItem('token'), 10);

    if (isNaN(token) || token === null) {
      token = 0;
    } else {
      token = parseInt(localStorage.getItem('token'), 10);
    }

    this.addtocartObj = {
      "BasketID": bid,
      "ProductID": productid,
      "VariantID": variantid,
      "Quantity": 1,
      "UserID": token
    };

    this.basketService.addToBasket(this.addtocartObj).subscribe(response => {
      if (response.status) {
        this.basket = response;
        this.toastr.info(this.basket.message, '', {
          tapToDismiss: true
        });
        count_cart_items_quantity('+',null);
        localStorage.setItem('basket_id', this.basket.data.basketID.toString());
        localStorage.setItem('token', this.basket.data.userID.toString());
        localStorage.setItem('user_id', this.basket.data.userID.toString());

        try {
          addToCartJS(this.prod);
          addToCartJSFB(this.prod);
        } catch(e) {
          console.log('error catch');
        }
      }
    }, error => {
      console.log(error);
    });

  }

  instructorGet() {
    console.log('instructorGet');
    this.accountService.getInstructor(
      String(this.instructorUserID)).subscribe(response => {
        console.log('response');
        if (response.status === true) {
          this.instructor = response.data;
          
        }
      }, error => {
        console.log(error);
      });
  };

  courseGet() {
    const request = {
      "productID": this.activatedRoute.snapshot.params.ProductID,
      "variantID": this.activatedRoute.snapshot.params.VariantID,
      "userID": this.accountService.getSimpleUserID()
    };

    this.articleService.courseGet(request).subscribe(response => {

      if (response.status) {
        this.prod = response.data;
        this.empty = false;
        this.aimdes = response.data.aimdes;
        this.youWillLearn = response.data.youWillLearn;
        this.deviceRequirements = response.data.deviceRequirements;
        this.chapters = response.data.chapters;
        this.realPrice = response.data.realPrice;
        this.price = response.data.price;
        this.name = response.data.name;
        this.titleService.setTitle(this.name + ' | c-xperto');
        this.valoration = response.data.rating;
        this.category = response.data.category;
        this.courseLevel = response.data.courseLevel;
        this.code = response.data.code;
        this.description = response.data.description;
        this.productID = response.data.productID;
        this.variantID = response.data.variantID;
        this.enabled = response.data.enabled;
        this.instructorUserID = response.data.userID;
        this.product = response.data;
        this.sourceUrl = response.data.initialVideoLink;
        this.poster = response.data.imageFileName1;
        this.url = encodeURIComponent(window.location.href);

        console.log("this.instructorUserID: " + this.instructorUserID);

        this.instructorGet();

        try {
          initVideo(this.sourceUrl);
          pageViewJS(response.data);
          viewContentJSFB(response.data);
        } catch(e) {
          console.log('error catch');
        }
      } else {
        this.empty = true;
        this.name = 'Curso no encontrado';
      }
    }, error => {
      console.log(error);
    });
  }

  getRelatedCourses() {

    const request = {
      "productID": this.activatedRoute.snapshot.params.ProductID,
      "variantID": this.activatedRoute.snapshot.params.VariantID,
      "userID": this.accountService.getSimpleUserID()
    };

    this.articleService.getRelatedCourses(request)
      .subscribe(response => {
        this.RelatedCourses = response.data;
        setTimeout(lazyLoading,500);
      }, error => {
        console.log(error);
      });
  }

  getComments() {
    const request = {
      "ProductID": this.activatedRoute.snapshot.params.ProductID,
      "VariantID": this.activatedRoute.snapshot.params.VariantID,
    };
    this.articleService.courseGetComments(request).subscribe(response => {
      this.commentProducstList = response;
    }, error => {
      console.log(error);
    });
  }

  courseGetRatings() {
    const request = {
      "ProductID": this.activatedRoute.snapshot.params.ProductID,
      "VariantID": this.activatedRoute.snapshot.params.VariantID,
    };
    this.articleService.courseGetRatings(request).subscribe(response => {
      this.MoreValorations = response;
      this.votes = 0;
      for (let i of response) {
        this.votes = (this.votes + i.totals);
      }

    }, error => {
      console.log(error);
    });
  }

  formattedDate(date) {
    const event = new Date(date);
    const dateOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    };
    return event.toLocaleDateString('es-ES', dateOptions);
  }

  hasTypeComment(event) {
    const comment = (<HTMLInputElement>document.getElementById('comment'));
    if (comment.value.trim() === '') {
      if (this.commentMsgButton === "Sólo calificar") {
        this.commentMsgButton = "Sólo calificar";
      } else {
        this.commentMsgButton = "Calificar o comentar";
      }
    } else {
      if (this.commentMsgButton === "Sólo calificar") {
        this.commentMsgButton = "Calificar y comentar";
      } else {
        this.commentMsgButton = "Sólo comentar";
      }
    }
  }

  goToProduct(gourl) {
    window.location.href= gourl;
  }

  fb_share(w,h) {
    return fbshare(w,h);
  }

  is_mobile() {
    return fbismobile();
  }

  toWhatsApp(url) {
    window.location.href = url;
  }

}
