import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { IUser, IGeneral, IOrderFull, IUserLogin, IInstructorGeneral,
         IInstructorResponse, IInstructorLoginResponse,
         IInstructorCourseResponse } from '../shared/models/user';
import { IResponse, IRequest, ICourseProduct,
         IResponseList, ICourseAvailable, ISalesCourses,
         IResponseCertification } from '../shared/models/product';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IAddress } from '../shared/models/address';
import { BasketService } from '../basket/basket.service';
import { IInstructorReq } from '../shared/models/instructor';

declare const COUNTCART: any;

function count_cart_items_quantity(op,count) {
  COUNTCART.QUANTITY(op,count);
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseUrl = environment.apiUrl;
  private currentUserSource = new ReplaySubject<IUser>(1);
  currentUser$ = this.currentUserSource.asObservable();
  errors: string;
  falseUser: IUser;
  userObject: any;

  constructor(private http: HttpClient,
              private router: Router,
              private basketService: BasketService) { }

  loadCurrentUser(token: string) {
    if (token === null) {
      this.currentUserSource.next(null);
      return of(null);
    }

    if(this.getSimpleBasketID() > 0) {
      // clear basket id if has convertion
      this.isBasketConverted(token, this.getSimpleBasketID());
    }

    this.getSimpleBasketID();

    return this.http.get(this.baseUrl + 'account?UserID=' + token).pipe(
      map((user: IUser) => {
        if (user) {
          this.getLoadBasketCounter();
          localStorage.setItem('user_id', user.userID.toString());
          localStorage.setItem('token', user.userID.toString());
          localStorage.setItem('accountTypeID', user.accountTypeID.toString());
          this.currentUserSource.next(user);
        }
      })
    );
  }

  login(values: IUserLogin) {
    return this.http.post<IGeneral>(this.baseUrl + 'account/login', values).pipe(
      map((user: IGeneral) => {
        if (user.status === true) {
          this.getLoadBasketCounter();
          localStorage.setItem('user_id', user.data.userID.toString());
          localStorage.setItem('token', user.data.userID.toString());
          localStorage.setItem('accountTypeID', user.data.accountTypeID.toString());
          this.currentUserSource.next(user.data);
        } else {
          this.errors = user.message;
        }
        return user;
      }, error => {
        console.log(error);
      })
    );
  }

  loginSocial(values: IUser) {
    return this.http.post<IGeneral>(this.baseUrl + 'account/registersocial', values).pipe(
      map((user: IGeneral) => {
        if (user.status === true) {
          this.getLoadBasketCounter();
          localStorage.setItem('user_id', user.data.userID.toString());
          localStorage.setItem('token', user.data.userID.toString());
          localStorage.setItem('accountTypeID', user.data.accountTypeID.toString());
          this.currentUserSource.next(user.data);
        } else {
          this.errors = user.message;
        }
        return user;
      }, error => {
        console.log(error);
      })
    );
  }

  register(values: any) {
    return this.http.post<IGeneral>(this.baseUrl + 'account/register', values).pipe(
      map((user: IGeneral) => {
        if (user.status === true) {
          this.getLoadBasketCounter();
          localStorage.setItem('user_id', user.data.userID.toString());
          localStorage.setItem('token', user.data.userID.toString());
          localStorage.setItem('accountTypeID', user.data.accountTypeID.toString());
          this.currentUserSource.next(user.data);
        } else {
          this.errors = user.message;
        }
        return user;
      }, error => {
        console.log(error);
      })
    );
  }

  registersocial(values: any) {
    return this.http.post<IGeneral>(this.baseUrl + 'account/registersocial', values).pipe(
      map((user: IGeneral) => {
        if (user.status === true) {
          this.getLoadBasketCounter();
          localStorage.setItem('user_id', user.data.userID.toString());
          localStorage.setItem('token', user.data.userID.toString());
          localStorage.setItem('accountTypeID', user.data.accountTypeID.toString());
          this.currentUserSource.next(user.data);
        } else {
          this.errors = user.message;
        }
        return user;
      }, error => {
        console.log(error);
      })
    );
  }

  registerInstructor(values: IInstructorReq) {
    return this.http.post<IInstructorGeneral>(this.baseUrl + 'instructor/register', values).pipe(
      map((response: IInstructorGeneral) => {
        if (response.status === true) {
          localStorage.setItem('user_id', response.data.userID.toString());
          localStorage.setItem('token', response.data.userID.toString());
          localStorage.setItem('accountTypeID', response.data.accountTypeID.toString());
        }
        return response;
      })
    );
  }

  
  uploadS3CVCompleted(ID, URL) {
    console.log('entro uploadS3CVCompleted');
    this.http.post
      (this.baseUrl + 'instructor/updatecv',
       {"ID":   parseInt(ID),
        "URL":    URL}).pipe();
  }

  uploadS3PhotoCompleted(ID, URL) {
    console.log('entro uploadS3PhotoCompleted');
    this.http.post
      (this.baseUrl + 'instructor/updatephoto',
       {"ID":   parseInt(ID),
        "URL":    URL}).pipe();
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('imageBase64');
    localStorage.setItem('user_id', '0');
    localStorage.setItem('basket_id', '0');
    localStorage.setItem('accountTypeID', '0');
    this.getLoadBasketCounter();
    this.currentUserSource.next(null);
    this.router.navigateByUrl('/');
  }

  checkEmailExists(email: string) {
    return this.http.get(this.baseUrl + 'account/emailexists?email=' + email);
  }

  getUserAddress() {
    return this.http.get<IAddress>(this.baseUrl + 'account/address');
  }

  updateUserAddress(address: IAddress) {
    return this.http.put<IAddress>(this.baseUrl + 'account/address', address);
  }

  getSimpleUserID() {
    let userid = 0;
    if ( localStorage.getItem('token') !== null) {
      userid = parseInt(localStorage.getItem('token'), 10);
    }
    return userid;
  }

  geSimpleAccountTypeID() {
    let accountypeid = 0;
    if ( localStorage.getItem('accountTypeID') !== null) {
      accountypeid = parseInt(localStorage.getItem('accountTypeID'), 10);
    }
    return accountypeid;
  }

  getSimpleBasketID() {
    let basketid = 0;
    if ( localStorage.getItem('basket_id') !== null) {
      basketid = parseInt(localStorage.getItem('basket_id'), 10);
    }
    return basketid;
  }

  orderGetFull(userid: string) {

    return this.http.get(this.baseUrl + 'order/getbyuser?UserID=' + userid)
      .pipe(
        map((user: IOrderFull[]) => {
          return user;
        })
      );

  }

  getAvailableCourses(userid: string) {
    return this.http.get(this.baseUrl + 'course/get_availableCourse_by_user?UserID=' + userid)
      .pipe(
        map((courses: ICourseAvailable[]) => {
          return courses;
        })
      );
  }

  sendForgotPasswordRequest(email: string) {
    return this.http.get(this.baseUrl + 'account/forgotpassword?login=' + email)
      .pipe(
        map(() => {
          return true;
        }, error => {
          console.log(error);
        })
      );
  }

  getLoadBasketCounter() {
    if (localStorage.getItem('basket_id') === null) {
      return;
    }
    let bid = parseInt(localStorage.getItem('basket_id'), 10);
    if (isNaN(bid) || bid === null) {
      bid = 0;
    } else {
      bid = parseInt(localStorage.getItem('basket_id'), 10);
    }
    let token = parseInt(localStorage.getItem('user_id'), 10);
    if (isNaN(token) || token === null) {
      token = 0;
    } else {
      token = parseInt(localStorage.getItem('user_id'), 10);
    }
    const resquest = {
      "basketID": bid,
      "userID": token
    };

    this.basketService.getFull(resquest).subscribe(response => {
      if (response.status) {
        const totalQuantity = response.data.totalQuantity;
        setTimeout(function() { count_cart_items_quantity('init',totalQuantity) },1000);
      } else {
        setTimeout(function() { count_cart_items_quantity('init',0) },1000);
      }
    }, error => {
      console.log(error);
    });
  }

  isBasketConverted(token, basketid) {
    if (basketid === null ||
        isNaN(basketid) ||
        basketid === 0) {
      return false;
    }

    if (token === null ||
        isNaN(token) ||
        token === 0) {
      return false;
    }

    this.basketService.getStatus({
      "basketID": basketid,
      "userID": token
    }).subscribe(response => {
      if(!response.status) {
        //console.log('basket get status: '+response.message);
      }
      else if(response.data.convertedToOrder === 0){
        localStorage.setItem('basket_id','0');
      }

    }, error => {
      console.log(error);
    });
  }

  getCertification(id) {
    return this.http.post<IResponseCertification>(this.baseUrl+'string/to/url',
                                                  {'orderItemID':id}
                                                 );
  }

  fileUploadCV(val) {
    return this.http.post
      <IGeneral>(this.baseUrl + 'instructor/uploadcv', val).pipe(
        map((response: IGeneral) => {
          return response;
        }, error => {
          console.log(error);
        })
      );
  }

  fileUploadIMG(val) {
    return this.http.post
      <IGeneral>(this.baseUrl + 'instructor/uploadphoto', val).pipe(
        map((response: IGeneral) => {
          return response;
        }, error => {
          console.log(error);
        })
      );
  }

  getInstructor(token: string) {
    console.log("getInstructor");
    return this.http.get(this.baseUrl + 'instructor/getbyuserid?userID=' + token).pipe(
      map((response: IInstructorLoginResponse) => {
        return response;
      })
    )
  }

  getInstructorCourses(token: string) {
    return this.http.get(this.baseUrl + 'courses/getbyinstructor?userID=' + token).pipe(
      map((response: ICourseProduct[]) => {
        return response;
      })
    )
  }

  getInstructorSalesCourses(token,start,end,courseid) {
    return this.http.post
      (this.baseUrl + 'order/getbysalesbyinstructor',
       {"UserID":   parseInt(token),
        "start":    start,
        "end":      end,
        "CourseID": courseid}).pipe(
          map((course: ISalesCourses[]) => {
            return course;
          }, error => {
            console.log(error);
          })
        );
  }

  //MAIL RESPONSE REQUEST INSTRUCTOR
  mailRequestInstructor(request:any) {
    return this.http.post<IResponse>(this.baseUrl + 'instructor/instructorrequestmail', request).pipe(
        map((response: IResponse) => {
          return response;
        }, error => {
          console.log(error);
        })
      );
  }

}
