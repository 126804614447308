<div class="form-label-group">
    <input [ngClass]="(controlDir && controlDir.control && controlDir.control.touched) ? !controlDir.control.valid ? 'is-invalid' : 'is-valid' : null"
           [type]="type"
           (input)="onChange($event.target.value)"
           (blur)="onTouched()"
           id="{{label}}"
           #input
           class="form-control"
           placeholder="{{label}}">

    <div *ngIf="controlDir && controlDir.control && controlDir.control.status === 'PENDING'" class="fa fa-spinner fa-spin loader"></div>

    <label for="{{label}}">{{label}}</label>
    <div class="invalid-feedback" *ngIf="(controlDir && controlDir.control && !controlDir.control.valid && controlDir.control.touched)">
        <span *ngIf="controlDir.control.errors?.required">{{label}} es requerido</span>
        <span *ngIf="controlDir.control.errors?.pattern && type === 'email'">Correo electrónico inválido</span>
        <span *ngIf="controlDir.control.errors?.pattern && type !== 'email'">{{label}} inválido</span>
    </div>

    <div class="invalid-feedback d-block" *ngIf="(controlDir && controlDir.control && !controlDir.control.valid && controlDir.control.dirty)">
        <span *ngIf="controlDir.control.errors?.emailExists">Correo electrónico en uso</span>
    </div>
</div>
