<!-- \\ Banner -->
<div id="banner-page"
     class="banner-top container-fluid lazy"
     style="background: url(/assets/images/graficos-banner.png) top, url(/assets/images/banner.jpg);">

    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 col-md-4 text-center">
            <h1 class="text-white display-3">Cambiar Contraseña</h1>
        </div>
    </div>
</div>
<!-- // Banner -->

<div *ngIf="status" class="container login py-5">
    <div class="row justify-content-center py-5">
        <div class="col-12 col-sm-10 col-md-8 col-lg-5">
            <form [formGroup]="forgotPasswordForm"
                  (ngSubmit)="onSubmit()">
                <app-text-input formControlName="displayPassword"
                                [label]="'🔐 Contraseña'"
                                [type]="fptype">
                </app-text-input>
                <app-text-input formControlName="repeatDisplayPassword"
                                [label]="'🔐 Repetir Contraseña'"
                                [type]="fptype">
                </app-text-input>
                <ul class="text-danger list-unstyled" *ngIf="messages.length > 0">
                    <li *ngFor="let message of messages">
                        {{ message }}
                    </li>
                </ul>
                <button class="boton-enviar mt-4 w-100 border-0 bg-light-purple text-white p-4 mb-1"
                        type="button"
                        (click)="switchHidenDisplayPassword(fptype)">
                    Ver la contraseña
                </button>
                <button class="boton-enviar mt-4 w-100 border-0 bg-purple text-white p-4 mb-5"
                        type="submit">
                    Enviar
                </button>
            </form>
        </div>
    </div>
</div>

<div *ngIf="!status" class="container login py-5">
    <div class="row justify-content-center py-5">
        <div class="col-12 col-sm-10 col-md-8 col-lg-5">
            <h2>{{message}}</h2>
        </div>
    </div>
</div>
