import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { BusyService } from 'src/app/core/services/busy.service';
import { ToastrService } from 'ngx-toastr';
import { OrderStatusService } from '../order-status/order-status.service';

declare const GAPPS: any;

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  idhash: string;
  emailOrder: string;
  orderNro: string;

  constructor(private orderStatusService: OrderStatusService,
              private busyService: BusyService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService) { }

  ngOnInit() {
    window.scroll(0,0);
    this.get();
    this.idhash = this.activatedRoute.snapshot.params.idhash;
    this.fixModalIssue();
  }

  goToTrackYourOrder(idhash) {
    this.router.navigateByUrl('/estado-orden/'+idhash);
  }

  fixModalIssue() {

    // get modals
    const modals = document.getElementsByClassName('modal');

    // on every modal change state like in hidden modal
    for(let i=0; i<modals.length; i++) {
      modals[i].classList.remove('show');
      modals[i].setAttribute('aria-hidden', 'true');
      modals[i].setAttribute('style', 'display: none');
    }

    const modalopen = document.getElementsByClassName('modal-open');

    for(let i=0; i<modalopen.length; i++) {
      modalopen[i].setAttribute('style', 'overflow-y: scroll');
    }

    // get modal backdrops
    const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove every modal backdrop
    for(let i=0; i<modalsBackdrops.length; i++) {
      document.body.removeChild(modalsBackdrops[i]);
    }

  }

  get() {
    this.busyService.busy();
    this.orderStatusService.getFull(this.activatedRoute.snapshot.params.idhash).subscribe(response => {
      this.busyService.idle();
      if ( response.status === true ) {
        try {
          GAPPS.THANKYOUPAGE(response.data.items,
                             response.data
                            );
        } catch (e) {
          console.log(e);
          console.log('^ catch error');
        }
        this.emailOrder = response.data.email;
        this.orderNro =
          response.data.dateCreated.substring(0, 4) +
          '-' +
          response.data.orderID
        ;
      } else {
        alert(response.message);
      }
    }, error => {
      console.log(error);
    });
  }

}
